import { getMetaTemplateData, searchAssetByType } from "helpers/mb-api_helper";
import FormComponent from "pages/ContentManagement/FormComponent";
import React, { useEffect, useState } from "react";
import SearchableSelect from "./SearchableSelect";
import RightDrawer from "Layout/RightDrawer";
import { useSelector } from "react-redux";

interface Component {
  field: string;
  formValues: { [key: string]: any };
  setFormValues: React.Dispatch<React.SetStateAction<{ [key: string]: any }>>;
  setValueToForm: (fieldName: string, value: any) => void;
  setMultiLingualValue: (field: string, value: any, activeLang: string) => void;
  element: {
    shortDesc?: string;
    longDesc?: {
      title: string;
      body: string;
    };
    name: string;
    referenceType: string;
    referenceField: string;
    uiElement: string;
    cols: number;
  };
}

const SearchBox: React.FC<Component> = ({
  element,
  field,
  formValues,
  setFormValues,
  setValueToForm,
  setMultiLingualValue,
}) => {
  const sections = [
    "pgRating",
    "defaultLanguage",
    "languages",
    "isDAIEnabled",
    "daiAssetKey",
    "isDVREnabled",
    "businessRule",
  ];
  const emptySearchBox = {};

  const selectUserMeta = (state: any) => state.User.meta;
  const meta = useSelector(selectUserMeta);
  const [searchBoxFormData, setSearchBoxFormData] = useState(
    formValues[field] || [emptySearchBox]
  );
  const [fields, setFields] = useState<any>({});
  const [assetNames, setAssetNames] = useState<any>({});

  const handleAddSection = () => {
    setSearchBoxFormData((prevData: any) => [...prevData, emptySearchBox]);
  };

  const handleRemoveSection = (index: number) => {
    setSearchBoxFormData((prevData: any) =>
      prevData.filter((_: any, i: number) => i !== index)
    );
  };

  const handleChange = async (id: any, index: number) => {
    setSearchBoxFormData((prevVal: any) =>
      prevVal.map((value: any, i: number) =>
        i === index ? { ...value, mediaFile: id.value } : value
      )
    );
  };

  // const handleFieldChange = (index: number, updateFunc: any) => {
  //   setSearchBoxFormData((prevData: any) =>
  //     prevData.map((obj: any, i: number) =>
  //       i === index ? updateFunc(obj) : obj
  //     )
  //   );
  // };

  const handleFieldChange = (index: number, field: string, value: string) => {
    const updatedContributors = [...formValues[field]];
    updatedContributors[index][field] = value;
    setSearchBoxFormData(updatedContributors);

    setValueToForm(field, updatedContributors);
  };

  const handleMultiLingualFieldChange = (
    index: number,
    subField: string,
    value: any,
    activeLang: string
  ): void => {
    // Determine which data source to use
    const formData =
      activeLang === meta.lang ? formValues : formValues.i18n[activeLang];

    // Create a new copy of the array to avoid mutating the original one
    let updatedContributors = [...(formData[field] || [{}])];

    // Ensure immutability by spreading the previous object and updating the field
    updatedContributors[index] = {
      ...updatedContributors[index],
      [subField]: value, // Use bracket notation to dynamically update 'subField'
    };
    // setContributorsData(updatedContributors);

    setMultiLingualValue(field, updatedContributors, activeLang);
  };

  useEffect(() => {
    const getFieldData = async () => {
      try {
        const fieldRes: any = await getMetaTemplateData("media");
        const response: any = await searchAssetByType("media", "");
        const responseData: any = response;
        setAssetNames(responseData.list);
        setFields(fieldRes.properties);
      } catch (err) {
        console.error(err);
      }
    };
    getFieldData();
  }, []);

  useEffect(() => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [field]: searchBoxFormData,
    }));
  }, [searchBoxFormData, field, setFormValues]);

  return (
    <div className={`lg:col-span-${element.cols || 12} flex  flex-col gap-3`}>
      <div className="flex flex-col gap-1 mb-2">
        <div className="flex items-center gap-2">
          <h5>{element.name}</h5>
          {element.longDesc && (
            <RightDrawer
              title={element.longDesc.title}
              body={element.longDesc.body}
            />
          )}
        </div>
        {element.shortDesc && element.shortDesc !== "" && (
          <span className="text-sm">{element.shortDesc}</span>
        )}
      </div>
      {searchBoxFormData?.map((value: any, i: number) => (
        <div
          key={i}
          className="grid grid-cols-1 gap-5 lg:grid-cols-12 p-4 border rounded-lg shadow-lg relative"
        >
          <div className={`lg:col-span-${element.cols || 12} `}>
            <label className="inline-block mb-2 text-base font-medium">
              Media File
            </label>
            <SearchableSelect
              onSelect={handleChange}
              value={value?.mediaFile || ""}
              assetsOptions={assetNames}
              index={i}
              assetType={"media"}
            />
          </div>
          <div className=" absolute top-2 right-2">
            {searchBoxFormData?.length > 1 && (
              <button
                className="text-mb-red/90 hover:text-mb-red"
                onClick={() => handleRemoveSection(i)}
              >
                {" "}
                <svg
                  className="w-6 h-6 "
                  fill="currentColor"
                  focusable="false"
                  aria-hidden="true"
                  viewBox="0 0 24 24"
                  data-testid="CancelIcon"
                >
                  <path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2m5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12z"></path>
                </svg>
              </button>
            )}
          </div>
          {sections?.map((field: any, index: number) => {
            if (fields[field]?.showInUi) {
              return (
                <FormComponent
                  setValueToForm={(field: string, value: any) =>
                    handleFieldChange(index, field, value)
                  }
                  setMultiLingualValue={(
                    field: string,
                    value: any,
                    activeLang: string
                  ) =>
                    handleMultiLingualFieldChange(
                      index,
                      field,
                      value,
                      activeLang
                    )
                  }
                  key={index}
                  field={field}
                  formValues={value}
                  setFormValues={setFormValues}
                  element={fields[field]}
                />
              );
            }
            return null;
          })}
        </div>
      ))}
      <div>
        <button
          className="btn bg-mb-blue text-white"
          onClick={handleAddSection}
        >
          Add More {element.name}
        </button>
      </div>
    </div>
  );
};

export default SearchBox;
