import { APIClient } from "./api_helper";

import * as url from "./mbentcms_url_helper";

const api = new APIClient();

export const getMetaConfig = () => api.get(url.GET_META_CONFIG);

export const getRules = () => api.get(url.RULE);

export const createRuleByName = (title: string) =>
  api.create(url.RULE, { title: title });

export const updateRule = (values: any,id:string|undefined) => api.put(`${url.RULE}/${id}`, values);

export const getRulesById = (id: string | undefined) =>
  api.get(`${url.RULE}/${id}`);
