import RightDrawer from "Layout/RightDrawer";
import countries from "lib/countries";
import isoLangs from "lib/languages";
import React, { useEffect, useState } from "react";
import CreatableSelect from "react-select/creatable";

interface Component {
  field: string;
  formValues: { [key: string]: any };
  setFormValues: React.Dispatch<React.SetStateAction<{ [key: string]: any }>>;
  setValueToForm: (fieldName: string, value: any) => void;
  element: {
    shortDesc?: string;
    longDesc?: {
      title: string;
      body: string;
    };
    name: string;
    validValues?: any[];
    validValuesStatic?: "languages" | "countries";
    cols: number;
  };
}

interface Option {
  readonly label: string;
  readonly value: string;
  readonly isDisabled?: boolean;
}

const SelectBox: React.FC<Component> = ({
  field,
  formValues,
  setValueToForm,
  element,
}) => {
  const [options, setOptions] = useState<Option[]>([]);

  useEffect(() => {
    if (element.validValues) {
      setOptions(
        element.validValues.map((v) => ({
          value: v,
          label: v === true ? "Yes" : v === false ? "No" : v,
        }))
      );
    } else if (element.validValuesStatic) {
      switch (element.validValuesStatic) {
        case "languages":
          setOptions(
            Object.keys(isoLangs).map((key) => ({
              value: key,
              label: isoLangs[key].name,
            }))
          );
          break;
        case "countries":
          setOptions(
            Object.keys(countries).map((key) => ({
              value: key,
              label: countries[key as keyof typeof countries], // Explicitly type the key
            }))
          );
          break;
        default:
          break;
      }
    }
  }, [element]);

  const handleChange = (selectedOption: Option | null) => {
    const value = selectedOption ? selectedOption.value : "";
    setValueToForm(field, value);
    // setFormValues((prevValues) => ({
    //   ...prevValues,
    //   [field]: selectedOption ? selectedOption.value : "",
    // }));
  };

  return (
    <div className={`lg:col-span-${element.cols || 6}`}>
      <div className="">
        <div className="flex flex-col gap-1 mb-2">
          <div className="flex items-center gap-2">
            <label className="inline-block text-base font-medium">
              {element.name}
            </label>
            {element.longDesc && (
              <RightDrawer
                title={element.longDesc.title}
                body={element.longDesc.body}
              />
            )}
          </div>
          {element.shortDesc && element.shortDesc !== "" && (
            <span className="text-sm">{element.shortDesc}</span>
          )}
        </div>
        <CreatableSelect
          className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-mb-blue disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
          id="choices-single-no-search"
          name="choices-single-no-search"
          isClearable
          isSearchable={true}
          options={options}
          onChange={handleChange}
          value={
            options.find((option) => option.value === formValues[field]) || null
          }
        />
      </div>
    </div>
  );
};

export default SelectBox;
