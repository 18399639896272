
import { getPreSignedUrl, uploadFile } from 'helpers/mb-api_helper';
import { Info, X } from 'lucide-react';
import React, { useCallback, useEffect, useState } from 'react';
import Popup from 'reactjs-popup';
import { v4 as uuidv4 } from "uuid";

interface InputProps {
  name:string  
  props:any
  formData?: any;
  setFormData: React.Dispatch<React.SetStateAction<any>>
}

const FileUpload: React.FC<InputProps> = ({name, props, formData={}, setFormData }) =>{
const [showHelper,setShowHelper] = useState(false)
const [file,setFile] = useState(formData[name]?formData[name]:"")
const onUpload = useCallback(async (e:any)=>{
    let file = e.target.files[0]
    let fileName = uuidv4()
    try{
        let signedUrl:any = await getPreSignedUrl(`images/${fileName}.${file.type.replace("image/", "")}`);
        let uploadFileRes = await uploadFile(signedUrl.url, file);
        setFile(signedUrl.file)
        setFormData({...formData,[name]:signedUrl.file})
    }catch(e){
        console.log(e)
    }
},[formData])
return (
  <div className={`flex flex-col gap-1 ${props.cols?`lg:col-span-${props.cols}`:'lg:col-span-6'}`}>
    <div className='flex gap-1 items-center'>
      <label className='text-sm'>{props.name}</label>
      {props.longDesc && 
      <>
        <button className='text-mb-blue' onClick={()=>setShowHelper(true)}><Info size={15} /></button>
        <Popup
          open={showHelper}
          onClose={()=>setShowHelper(false)} 
          modal
          lockScroll
          closeOnDocumentClick
          contentStyle={{top:0,right:0,height:'100vh',position:'fixed'}}
          overlayStyle={{background:'rgba(0,0,0,0.6)',zIndex:999999}}
        >
        <div className='h-screen bg-white w-[20rem] relative'>
          <button onClick={()=>setShowHelper(false)} className='absolute right-4 top-4'><X size={30} /></button>
          <div className='text-xl text-mb-blue border-b border-b-mb-blue font-bold p-4'>{props.longDesc.title}</div>
          <div className='text-sm text-gray-900 p-4 flex flex-col gap-4' dangerouslySetInnerHTML={{__html:props.longDesc.body}}></div>
        </div>  
        </Popup>
      </>
    } 
    </div>
    <span className='text-xs'>{props.shortDesc}</span>
        <input type='file' onChange={onUpload} />
        {file !== "" && <img src={`${process.env.REACT_APP_IMAGE_CDN}${file}`} width={100} height={100} alt='uploadedfile'  />}
    </div>
)};

export default FileUpload;