import { createSelector } from "@reduxjs/toolkit";
import {
  BarChart3Icon,
  BookOpenCheckIcon,
  FileSpreadsheetIcon,
  FileUp,
  FilmIcon,
  ListChecks,
  LucideCircleEllipsis,
  LucideMonitorDot,
  Mail,
  User2Icon,
  Users2Icon,
  Video,
} from "lucide-react";
import { useSelector } from "react-redux";

const selectProperties = createSelector(
  (state: any) => state.User,
  (user) => ({
    meta: user.meta,
  })
);
export const useMenuData = () => {
  const { meta } = useSelector(selectProperties);
  //console.log("Meta In Menu Data",meta)

  const menuData: any = [
    {
      label: "Analytics",
      isTitle: true,
    },
    {
      id: "dashboard",
      label: "Dashboards",
      link: "/dashboard/users",
      icon: <BarChart3Icon />,
      subItems: [
        {
          id: "user",
          label: "User",
          link: "/dashboard/users",
          parentId: "dashboard",
        },
        {
          id: "content",
          label: "Content",
          link: "/dashboard/content",
          parentId: "dashboard",
        },
        {
          id: "user",
          label: "Player",
          link: "/dashboard/player",
          parentId: "dashboard",
        },
        {
          id: "user",
          label: "Subscriptions",
          link: "/dashboard/subscription",
          parentId: "dashboard",
        },
      ],
    },
    {
      id: "reports",
      label: "Reports",
      link: "/reports/users",
      icon: <FileSpreadsheetIcon />,
      subItems: [
        {
          id: "user",
          label: "User",
          link: "/reports/users",
          parentId: "reports",
        },
        {
          id: "content",
          label: "Content",
          link: "/reports/content",
          parentId: "reports",
        },
        {
          id: "user",
          label: "Player",
          link: "/reports/player",
          parentId: "reports",
        },
        {
          id: "user",
          label: "Subscriptions",
          link: "/reports/subscriptions",
          parentId: "reports",
        },
      ],
    },
    {
      label: "Content Management",
      isTitle: true,
    },

    {
      id: "media",
      label: "Media",
      icon: <FilmIcon />,
      link: "/assets/media",
    },
    {
      id: "assets",
      label: "Content",
      icon: <Video />,
      link: `/assets/${meta.assetTypes[0]}`,
      subItems: [
        ...meta?.assetTypes?.map((asset: string) => {
          return {
            id: asset,
            label: asset.replace("_"," "),
            icon: <Mail />, // Ensure that <Mail /> is a valid React component
            link: `/assets/${asset}`,
            parentId: "assets",
          };
        }),
      ],
    },
    {
      id: "bulkUpload",
      label: "Bulk Upload",
      icon: <FileUp />,
      link: "/bulk-upload",
    },
    {
      label: "Store Front",
      isTitle: true,
    },
    {
      id: "platform",
      label: "Platforms",
      icon: <LucideMonitorDot />,
      link: "/platforms",
    },
    {
      id: "views",
      label: "Pages",
      icon: <BookOpenCheckIcon />,
      link: "/views",
    },
    {
      id: "menu",
      label: "Menus",
      icon: <LucideCircleEllipsis />,
      link: "/menus",
    },
    {
      id: "staticPage",
      label: "Static Pages",
      icon: <BookOpenCheckIcon />,
      link: "/manage/staticPage",
    },
    {
      label: "Entitlement",
      isTitle: true,
    },
    {
      id: "businessRules",
      label: "Business Rules",
      icon: <ListChecks />,
      link: "/business-rules",
      parentId: 2,
    },
    {
      label: "ACCESS CONTROL",
      isTitle: true,
    },
    {
      id: "adminUser",
      label: "Admin Users",
      icon: <User2Icon />,
      link: "/user/profile",
      parentId: 2,
    },
    {
      id: "teams",
      label: "Roles",
      icon: <Users2Icon />,
      link: "/user/roles",
      parentId: 2,
    },
  ];
  return menuData;
};
// export { menuData };
