import ActionBox from "components/formComponets/ActionBox";
import CheckBox from "components/formComponets/CheckBox";
import ContributorsBox from "components/formComponets/ContributorsBox";
import FileUpload from "components/formComponets/FileUpload";
import ImageBoxNoRatio from "components/formComponets/ImageBoxNoRatio";
import MediaUploader from "components/formComponets/MediaUploader";
import MultiSelectBox from "components/formComponets/MultiSelectBox";
import ObjectBox from "components/formComponets/ObjectBox";
import PlaybackUrlsBox from "components/formComponets/PlaybackUrlsBox";
import SelectBoxSearch from "components/formComponets/SelectBoxSearch";
import SubtitlesBox from "components/formComponets/SubtitlesBox";
import TextArea from "components/formComponets/TextArea";
import TextBox from "components/formComponets/TextBox";
import React from "react";
import DatePicker from "components/formComponets/DatePicker";
import SelectBox from "components/formComponets/SelectBox";
import SearchBox from "components/formComponets/SearchBox";
import { useSelector } from "react-redux";
import EditorClassic from "components/formComponets/Editor";
import NewSearchableSelect from "components/formComponets/NewSearchableSelect";
import TagBox from "components/formComponets/TagBox";
import DateTimePicker from "components/formComponets/DateWithTimezonePicker";
import GeoContributorsBox from "components/formComponets/GeoContributorsBox";
import MediaSelectBox from "components/formComponets/MediaSelectBox";
import StartEndTimeInputBox from "components/formComponets/StartEndTimeInputBox";
import EmptyBox from "components/formComponets/EmptyBox";
interface LongDesc {
  title: string;
  body: string;
}
interface FormComponentProps {
  element: {
    shortDesc: string;
    longDesc: LongDesc;
    cols: number;
    isMultiLingual: boolean;
    type: string;
    autoGenerate?: boolean;
    autoGenerateBy: string;
    referenceType: string;
    referenceField: string;
    uiElement: string;
    name: string;
    accept: string;
    // items?: any;
    validValues?: any;
    properties: { [key: string]: any };
    [key: string]: any;
    items: {
      properties: {
        name: {
          name: string;
        };
        role: {
          name: string;
        };
      };
    };
  };
  parent?: string;
  position?: number;
  field: string;
  formValues: any;
  formValue?: any;
  setFormValues: React.Dispatch<React.SetStateAction<{ [key: string]: any }>>;
  setValueToForm: (field: string, value: string) => void;
  setMultiLingualValue: (field: string, value: any, activeLang: string) => void;
  setChangeTheFormData?: React.Dispatch<React.SetStateAction<boolean>>;
}

const FormComponent: React.FC<FormComponentProps> = ({
  field,
  element,
  formValues,
  formValue = formValues,
  parent = "",
  position = 0,
  setFormValues,
  setChangeTheFormData,
  setValueToForm,
  setMultiLingualValue,
}) => {
  const selectUserMeta = (state: any) => state.User.meta;

  const meta = useSelector(selectUserMeta);

  const renderComponent = () => {
    switch (element.uiElement) {
      case "videoTimerBox":
        return (
          <StartEndTimeInputBox
            setValueToForm={setValueToForm}
            element={element}
            field={field}
            formValues={formValues}
            setFormValues={setFormValues}
          />
        );
      case "textBox":
        return (
          <TextBox
            element={element}
            setMultiLingualValue={setMultiLingualValue}
            field={field}
            formValues={formValue}
            setValueToForm={setValueToForm}
            setFormValues={setFormValues}
            meta={meta}
          />
        );
      case "textArea":
        return (
          <TextArea
            element={element}
            field={field}
            formValues={formValue}
            setFormValues={setFormValues}
            setMultiLingualValue={setMultiLingualValue}
            setValueToForm={setValueToForm}
            meta={meta}
          />
        );
      case "editor":
        return (
          <EditorClassic
            setValueToForm={setValueToForm}
            setMultiLingualValue={setMultiLingualValue}
            element={element}
            field={field}
            formValues={formValues}
            setFormValues={setFormValues}
            meta={meta}
          />
        );
      case "checkBox":
        return (
          <CheckBox
            setValueToForm={setValueToForm}
            element={element}
            field={field}
            formValues={formValues}
            setFormValues={setFormValues}
          />
        );
      case "selectBox":
        return (
          <SelectBox
            setValueToForm={setValueToForm}
            element={element}
            field={field}
            formValues={formValues}
            setFormValues={setFormValues}
          />
        );
      case "multiSelectBox":
        return (
          <MultiSelectBox
            setValueToForm={setValueToForm}
            element={element}
            field={field}
            formValues={formValues}
            setFormValues={setFormValues}
          />
        );
      case "selectBoxSearch":
        return (
          <SelectBoxSearch
            setValueToForm={setValueToForm}
            element={element}
            field={field}
            formValues={formValues}
            setFormValues={setFormValues}
          />
        );
      case "fileupload":
        return (
          <FileUpload element={element} field={field} formValues={formValues} />
        );
      case "objectBox":
        return (
          <ObjectBox
            setValueToForm={setValueToForm}
            setMultiLingualValue={setMultiLingualValue}
            element={element}
            field={field}
            formValues={formValue}
            setFormValues={setFormValues}
          />
        );
      case "datepicker":
        return (
          <DatePicker
            setValueToForm={setValueToForm}
            element={element}
            field={field}
            formValues={formValues}
            // setFormValues={setFormValues}
          />
        );
      case "dateTimePicker":
        return (
          <DateTimePicker
            setValueToForm={setValueToForm}
            element={element}
            field={field}
            formValues={formValues}
            setFormValues={setFormValues}
          />
        );
      case "actionBox":
        return (
          <ActionBox element={element} field={field} formValues={formValues} />
        );
      case "contributorsBox":
        return (
          <ContributorsBox
            setValueToForm={setValueToForm}
            setMultiLingualValue={setMultiLingualValue}
            element={element}
            field={field}
            formValues={formValue}
            setFormValues={setFormValues}
          />
        );
      case "subtitlesBox":
        return (
          <SubtitlesBox
            element={element}
            field={field}
            formValues={formValues}
          />
        );
      case "playbackUrlsBox":
        return (
          <PlaybackUrlsBox
            element={element}
            field={field}
            formValues={formValues}
          />
        );
      case "imageBoxNoRatio":
        return (
          <ImageBoxNoRatio
            setValueToForm={setValueToForm}
            element={element}
            field={field}
            formValues={formValues}
            setFormValues={setFormValues}
            setChangeTheFormData={setChangeTheFormData}
          />
        );
      case "imageBoxRatio":
        return (
          <ImageBoxNoRatio
            setValueToForm={setValueToForm}
            element={element}
            field={field}
            formValues={formValues}
            setFormValues={setFormValues}
            setChangeTheFormData={setChangeTheFormData}
          />
        );
      case "mediaUploader":
        return (
          <MediaUploader
            element={element}
            field={field}
            formValues={formValues}
            setFormValues={setFormValues}
            setChangeTheFormData={setChangeTheFormData}
          />
        );
      // case "cueTimingsBox":
      //   return (
      //     <CueTimingsBox
      //       element={element}
      //       field={field}
      //       formValues={formValues}
      //       setFormValues={setFormValues}
      //     />
      //   );
      case "serachBox":
        return (
          <SearchBox
            setValueToForm={setValueToForm}
            setMultiLingualValue={setMultiLingualValue}
            element={element}
            field={field}
            formValues={formValue}
            setFormValues={setFormValues}
          />
        );

      case "searchableSelect":
        return (
          <NewSearchableSelect
            setValueToForm={setValueToForm}
            element={element}
            field={field}
            formValues={formValues}
            setFormValues={setFormValues}
          />
        );
      case "mediaSelectBox":
        return (
          <MediaSelectBox
            setValueToForm={setValueToForm}
            setMultiLingualValue={setMultiLingualValue}
            element={element}
            field={field}
            formValues={formValue}
            setFormValues={setFormValues}
          />
        );

      case "tagBox":
        return (
          <TagBox
            setValueToForm={setValueToForm}
            element={element}
            field={field}
            formValues={formValues}
            // setFormValues={setFormValues}
          />
        );

      case "geoContributorsBox":
        return (
          <GeoContributorsBox
            setValueToForm={setValueToForm}
            setMultiLingualValue={setMultiLingualValue}
            element={element}
            field={"licensingUi"}
            formValues={formValues}
            // setFormValues={setFormValues}
          />
        );
      default:
        return <EmptyBox element={element} />;
    }
  };

  return (
    <>
      {element &&
        ((element.dependentField &&
          element.dependentValue === formValues[element.dependentField]) ||
          !element.dependentField) &&
        renderComponent()}
    </>
  );
};

export default FormComponent;
