import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { authProtectedRoutes, publicRoutes } from './allRoutes';
import Layout from 'Layout';
import NonAuthLayout from "Layout/NonLayout"
import AuthProtected from './AuthProtected';

const RouteIndex = () => {
  return (
    <React.Fragment>
      <div className="hidden">
          <div className="lg:col-span-1"></div>
          <div className="lg:col-span-2"></div>
          <div className="lg:col-span-3"></div>
          <div className="lg:col-span-4"></div>
          <div className="lg:col-span-5"></div>
          <div className="lg:col-span-6"></div>
          <div className="lg:col-span-7"></div>
          <div className="lg:col-span-8"></div>
          <div className="lg:col-span-9"></div>
          <div className="lg:col-span-10"></div>
          <div className="lg:col-span-11"></div>
          <div className="lg:col-span-12"></div>
      </div>
      <Routes>
        {authProtectedRoutes.map((route: any, idx: number) => (
          <Route
            key={idx}
            path={route.path}
            element={
              <AuthProtected>
                
                <Layout>
                  <route.component />
                </Layout>
              </AuthProtected>
            }
          />
        ))}
        {publicRoutes.map((route: any, idx: number) => (
          <Route
            path={route.path}
            key={idx}
            element={
              <NonAuthLayout>
                <route.component />
              </NonAuthLayout>
            } />
        ))}
      </Routes>
    </React.Fragment>
  );
};

export default RouteIndex;
