import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface FormValueState {
  formValue: {};
}

const initialState: FormValueState = {
  formValue: {},
};

const formValueSlice = createSlice({
  name: "editContent",
  initialState,
  reducers: {
    setEditContent(state: FormValueState, action: PayloadAction<any>) {
      state.formValue = action.payload;
    },
    setEditContentError(state: FormValueState, action: PayloadAction<any>) {
      state.formValue = {};
    },
  },
});

export const { setEditContent, setEditContentError } = formValueSlice.actions;
export default formValueSlice.reducer;
