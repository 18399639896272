import React from 'react';
interface InputProps {
  name:string  
  props:any
  formData?: any;
  setFormData: React.Dispatch<React.SetStateAction<any>>
}
const EmptyBox: React.FC<InputProps> = ({name, props, formData={}, setFormData }) =>{
return (<div className={`hidden lg:block ${props.cols?`lg:col-span-${props.cols}`:'lg:col-span-6'}`}></div>)};

export default EmptyBox;