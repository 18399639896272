import React, { useEffect, useState } from "react";
// import BulkMultipleSelectSection from "./BulkMultipleSelectSection";
import BreadCrumb from "Common/BreadCrumb";
import { capitalizeFirstLetter } from "coustumFuntions/capitalFirstLetter";
import { useSelector } from "react-redux";
import Select from "react-select";
import {
  createBulkImportJob,
  getMappingBulkData,
  getMetaTemplateData,
  getPreSignedUrl,
  mappingBulkData,
  uploadFile,
} from "helpers/mb-api_helper";
import { v4 as uuidv4 } from "uuid";
import NewBulkMulSelectOption from "./NewBulkMulSelectOption";
import SnackBar from "Layout/SnackBar";
import { useNavigate } from "react-router-dom";
import { SelectPicker } from "rsuite";
import { log } from "console";

interface Option {
  readonly label: string;
  readonly value: string;
  readonly isDisabled?: boolean;
}

const BulkUploadAddSection = () => {
  const selectUserMeta = (state: any) => state.User.meta;
  const meta = useSelector(selectUserMeta);
  const navigate = useNavigate();

  const [assetType, setAssetType] = useState<string>("");
  const [dropDownData, setDropDownData] = useState<any>("");
  const [firstRowData, setFirstRowData] = useState<string[] | null>(null);
  const [mappingData, setMappingData] = useState<any[]>([]);
  const [mappingJason, setMappingJason] = useState({});
  const [previousMappingId, setPreviousMappingId] = useState("");
  const [file, setFile] = useState({});
  const [name, setName] = useState("");
  const [validationError, setValidationError] = useState("");
  const [success, setSuccess] = useState("");
  const [mappingOption, setMappingOption] = useState("");

  const [assetTypeOptions, setAssetTypeOptions] = useState<any>([]);

  const handleAssetTypeChange = async (value: any) => {
    setAssetType(value ? value : "");
    try {
      const data = await getMetaTemplateData(value);
      const template: any = data;
      setDropDownData(template.properties);
    } catch (err) {
      console.log(err);
    }
  };
  const handleSetPreMappingId = async (value: any) => {
    setPreviousMappingId(value ? value : "");
  };

  const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]; // Use optional chaining to handle null
    if (file && file.type === "text/csv") {
      let fileName = uuidv4();
      try {
        let signedUrl: any = await getPreSignedUrl(
          `bulk/${fileName}.${file?.type.replace("text/", "")}`
        );
        await uploadFile(signedUrl.url, file);
        setFile("https://mbassets.stagingsites.in/" + signedUrl.file);
      } catch (e) {
        console.log(e);
      }

      const reader = new FileReader();
      reader.onload = (event: ProgressEvent<FileReader>): void => {
        const csvText = event.target?.result as string;
        const rows = csvText.split("\n");
        if (rows.length > 0) {
          const firstRowArray = rows[0].split(","); // Split the first row into an array
          setFirstRowData(firstRowArray);
          //   setMappingJason(
          //     firstRowArray.map((row) => {
          //       return { row: "" };
          //     })
          //   );
        }
      };
      reader.readAsText(file);
    } else {
      alert("Please upload a valid CSV file.");
    }
  };

  const handleSubmitFile = async () => {
    if (previousMappingId === "" && assetType !== "" && name === "") {
      setValidationError("Please enter a name");
    } else {
      try {
        let mapping_id = previousMappingId ?? "";
        if (previousMappingId === "") {
          const filterData = Object.fromEntries(
            Object.entries(mappingJason).filter(([key, value]) => value !== "")
          );

          console.log("filterData", filterData);
          console.log("file", file);

          const response: any = await mappingBulkData({
            name,
            content_type: assetType,
            data: filterData,
          });
          mapping_id = response.id;
        }

        try {
          await createBulkImportJob({
            file,
            content_type: assetType,
            mapping_id,
          });
          setSuccess("Add mapping successfully!");
          navigate("/bulk-upload");
        } catch (err) {
          console.error("something went wrong", err);
        }
      } catch (e) {
        console.error("something went wrong", e);
      }
    }
  };

  useEffect(() => {
    const options = meta.assetTypes.map((data: string) => ({
      value: data,
      label: capitalizeFirstLetter(data),
    }));
    setAssetTypeOptions(options);
  }, [meta]);
  useEffect(() => {
    const options = meta.assetTypes.map((data: string) => ({
      value: data,
      label: capitalizeFirstLetter(data),
    }));
    setAssetTypeOptions(options);
    const getMappingData = async () => {
      try {
        const response: any = await getMappingBulkData();
        const data =
          response?.list?.length > 0
            ? response?.list?.map((data: any) => ({
                value: data.id,
                label: capitalizeFirstLetter(data?.name),
              }))
            : [];
        setMappingData(data);
      } catch (e) {
        console.log("error: " + e);
      }
    };
    getMappingData();
  }, []);

  return (
    <React.Fragment>
      <div className="w-full min-h-screen flex flex-col">
        <BreadCrumb
          backRoute={"/bulk-upload"}
          title={"Add Upload"}
          pageTitle="Upload Section"
        />{" "}
        <div className="card">
          <div className="card-body min-h-screen">
            <div className="flex card px-4 py-2">
              <div className="flex flex-row">
                <h2 className="text-lg font-bold">Create Bulk Import Job</h2>
              </div>
            </div>

            <div className="mt-16 w-full flex items-start gap-10">
              <div className="flex flex-col gap-px">
                <p className="mb-4">Select content type</p>
                <SelectPicker
                  className="border !border-mb-blue !text-black rounded-md w-48"
                  name="choices-single-default"
                  data={assetTypeOptions}
                  onChange={(v, e) => handleAssetTypeChange(v)}
                  // value={assetTypeOptions.find(
                  //   (option: Option) => option.value === assetType
                  // )}
                  value={assetType}
                />
              </div>

              {/* Radio Buttons for Mapping Options */}
              {assetType !== "" && (
                <div className=" flex flex-col">
                  <p className="mb-4 w-48 px-2">Mapping Options</p>
                  <div className="flex flex-col gap-2">
                    <label className="flex flex-row gap-2">
                      <input
                        type="radio"
                        value="new"
                        checked={mappingOption === "new"}
                        onChange={() => {
                          setMappingOption("new");
                          setPreviousMappingId("");
                        }}
                      />
                      Create New Mapping
                    </label>
                    <label className="flex flex-row gap-2">
                      <input
                        type="radio"
                        value="existing"
                        checked={mappingOption === "existing"}
                        onChange={() => setMappingOption("existing")}
                      />
                      Existing Mapping
                    </label>
                  </div>
                </div>
              )}

              {assetType !== "" && mappingOption === "existing" && (
                <div className="">
                  <p className="mb-4">Previous mapping</p>
                  <SelectPicker
                    className="border !border-mb-blue !text-black rounded-md w-48"
                    name="choices-single-default"
                    data={mappingData}
                    onChange={(v, e) => handleSetPreMappingId(v)}
                    // value={mappingData.find(
                    //   (option: Option) => option.value === assetType
                    // )}
                    value={previousMappingId}
                  />
                </div>
              )}

              {previousMappingId === "" &&
                mappingOption === "new" &&
                assetType !== "" && (
                  <div className="flex flex-col gap-1">
                    <p className="mb-4">File Name</p>
                    <input
                      name={name}
                      placeholder="Enter name "
                      className="px-1 py-2 h-9 border !border-mb-blue rounded-md w-48 disabled:bg-gray-100 disabled:cursor-not-allowed"
                      type="text"
                      value={name}
                      onChange={(e) => {
                        if (validationError !== "") setValidationError("");
                        setName(e.target.value);
                      }}
                    />
                    {validationError !== "" && (
                      <p className="text-red-600 text-sm">{validationError}</p>
                    )}
                  </div>
                )}
              {assetType !== "" && mappingOption && (
                <div>
                  <p className="mb-4">Select csv file</p>
                  <div className="flex flex-col items-center">
                    <input
                      type="file"
                      accept=".csv"
                      onChange={handleFileUpload}
                      className="p-2 border border-gray-300 rounded-md"
                    />
                  </div>
                </div>
              )}
            </div>

            {previousMappingId === "" && firstRowData && assetType !== "" && (
              <div className="grid grid-cols-2 mt-6 gap-4">
                {firstRowData.map((row, index) => (
                  <NewBulkMulSelectOption
                    key={index}
                    dropDownData={dropDownData}
                    title={row}
                    setMappingJason={setMappingJason}
                  />
                ))}
              </div>
            )}

            {assetType !== "" && firstRowData && firstRowData.length > 0 && (
              <div className="p-4 w-full flex justify-end">
                <button
                  onClick={() => {
                    handleSubmitFile();
                  }}
                  className="w-28 btn bg-mb-blue text-white"
                >
                  Submit Data
                </button>
              </div>
            )}
            {success && (
              <SnackBar message={success} onClose={() => setSuccess("")} />
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default BulkUploadAddSection;
