import React, { useCallback, useEffect } from "react";
import { Check, Cross, UploadCloud, X } from "lucide-react";
import { useDropzone } from "react-dropzone";
import languages from "Common/languages";
import isoLangs from "lib/languages";
import CreatableSelect from "react-select/creatable";
import { Promise } from "bluebird";
import { randomUUID } from "crypto";
import { v4 as uuidv4 } from "uuid";

import { getPreSignedUrl, uploadFile } from "helpers/mb-api_helper";
import { createSelector } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import RightDrawer from "Layout/RightDrawer";
import { Label } from "@headlessui/react/dist/components/label/label";
import { Toggle } from "rsuite";

function ratio(wi: number, he: number) {
  var w = wi;
  var h = he;
  var d: number = 1; //gcd
  if (w < 0) w = -w;
  if (h < 0) h = -h;
  if (h > w) {
    var temp = w;
    w = h;
    h = temp;
  }
  var t = true;
  while (t) {
    w %= h;
    if (w == 0) {
      d = h;
      t = false;
    }
    h %= w;
    if (h == 0) {
      d = w;
      t = false;
    }
  }
  return wi / d + ":" + he / d;
}
interface InputProps {
    name:string  
    props:any
    formData?: any;
    setFormData: React.Dispatch<React.SetStateAction<any>>
    i18n?:string
  }
interface Option {
  readonly label: string;
  readonly value: string | boolean;
  readonly isDisabled?: boolean;
}

const formatBytes = (bytes: any, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };
  const getBaseName = (path: string) => {
    let path_array = path.split("/");
    return path_array[path_array.length - 1];
  };

const ImageUploader: React.FC<InputProps> = ({name, props, formData={}, setFormData,i18n }) => {
  let selectProperties = createSelector(
    (state: any) => state.User,
    (user) => ({
      meta: user.meta,
    })
  );
  let { meta } = useSelector(selectProperties);
  const [selectedFiles, setSelectedFiles] = React.useState<any>([]);
  const [progress, setProgress] = React.useState<number>(0);
  const [currentlyUploading, setCurrentlyUploading] = React.useState<number>(-1);
  const [isUploading, setIsUploading] = React.useState<Boolean>(false);
  const [isReadyToUpload, setIsReadyToUpload] = React.useState<Boolean>(false);
  const [options, setOptions] = React.useState<Option[]>( meta.languages.map((key: string) => ({
    value: key,
    label: isoLangs[key].name,
  })));
  const [error, setError] = React.useState<string>("");

  const onDrop = (files: any) => {
    files.map((file: any) => {
      let img = new Image();
      var objectUrl = URL.createObjectURL(file);
      img.src = objectUrl;
      img.onload = function (this: any) {
        Object.assign(file, {
          preview: objectUrl,
          formattedSize: formatBytes(file.size),
          language: meta.lang,
          uploaded: false,
          width: this.width,
          height: this.height,
          ratio: ratio(this.width, this.height),
        });
        setSelectedFiles(files);
        //startUpload()
      };
    });
  };
 

  const handleChange = useCallback(
    (file: any, selectedOption: Option | null,field:string) => {
      if (file.name) {
        let newSelectedFiles = selectedFiles.map((f: any, i: number) => {
          if (f.name === file.name) {
            f[field] = selectedOption?.value;
          }
          return f;
        });
        setSelectedFiles([...newSelectedFiles]);
      } else {
        let newSelectedFiles = formData[name].map((f: any, i: number) => {
          if (f.path === file.path) {
            f[field] = selectedOption?.value;
          }
          return f;
        });
        let newFormValues = { ...formData };
        //setFormData(name, [...newSelectedFiles]);
        newFormValues[name] = [...newSelectedFiles];
        //setChangeTheFormData(true);
        setFormData({ ...newFormValues });
      }
    },
    [selectedFiles]
  );
  const startUpload = useCallback(async () => {
    let fileResults = await Promise.mapSeries(
      selectedFiles,
      async (file: any, index: number) => {
        console.log("file", file);
        setCurrentlyUploading(index);
        let contentId = formData.id;
        let name = uuidv4();
        let signedUrl: any;
        signedUrl = await getPreSignedUrl(
          `${contentId}/posters/${name}.${file.type.replace("image/", "")}`
        );
        let uploadFileRes = await uploadFile(signedUrl.url, file);

        if (uploadFileRes.status === 200) {
          file.uploaded = true;
          file.uploadPath = signedUrl.file;
        } else {
          file.uploaded = false;
        }
        setProgress(Math.round(((index + 1) * 100) / selectedFiles.length));
        setCurrentlyUploading(-1);
        console.log(
          "Final Res",
          "Index:" + index,
          "Progress:" + progress,
          "Path:" + file.uploadPath
        );
        return file;
      }
    );
    console.log("formValues", fileResults);
    let newUploadedFiles = fileResults.map((file: any) => ({
      path: file.uploadPath,
      language: file.language,
      width: file.width,
      height: file.height,
      ratio: file.ratio,
    }));
    let newFormValues = { ...formData };
    newFormValues[name] = [
      ...(newFormValues[name] ? newFormValues[name] : []),
      ...newUploadedFiles,
    ];
    // setValueToForm(field, [
    //   ...(newFormValues[field] ? newFormValues[field] : []),
    //   ...newUploadedFiles,
    // ]);
    setFormData(newFormValues);
    setSelectedFiles([]);
    setIsReadyToUpload(false);
  }, [selectedFiles]);
  useEffect(() => {
    if (selectedFiles.length > 0) startUpload();
  }, [selectedFiles]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "image/*": [".png", ".jpg", ".jpeg", ".webp", ".JPG", ".PNG", ".JPEG"],
    },
    disabled:meta.lang!==i18n
  });

  return (
    <div className={`lg:col-span-${props.cols || 12}`}>
      <div className="">
        <div className="flex flex-col gap-1 mb-2">
          <div className="flex items-center gap-2">
            <h6>{props.name}</h6>
            {props.longDesc && (
              <RightDrawer
                title={props.longDesc.title}
                body={props.longDesc.body}
              />
            )}
          </div>
          {props.shortDesc && props.shortDesc !== "" && (
            <span className="text-sm">{props.shortDesc}</span>
          )}
        </div>
        <div aria-disabled={i18n!==meta.lang} className="flex group items-center justify-center border rounded-md aria-disabled:cursor-not-allowed aria-disabled:bg-gray-100 cursor-pointer bg-slate-100 dropzone border-slate-200 dark:bg-zink-600 dark:border-zink-500 dz-clickable">
          <div
            
            className="w-full py-5 text-lg text-center dz-message needsclick"
            {...getRootProps()}
          >
            <input {...getInputProps()} accept={props.accept} />
            <div className="mb-3">
              <UploadCloud className="block size-12 mx-auto text-slate-500 group-aria-disabled:text-gray-300 group-aria-disabled:fill-gray-300 fill-slate-200 dark:text-zink-200 dark:fill-zink-500"></UploadCloud>
            </div>

            <h5 className="mb-0 font-normal group-aria-disabled:text-gray-300 text-slate-500 text-15">
              Drag and drop your files or <a href="#!">browse</a> your files
            </h5>
            {i18n !== meta.lang && <p className="text-xs text-gray-500">Disabled</p>}
          </div>
        </div>
       

        {progress > 0 && (
          <div className="w-full bg-slate-200 rounded-full mt-1 h-2.5 dark:bg-zink-600">
            <div
              className="bg-mb-blue h-2.5 rounded-full animate-progress relative"
              style={{ width: `${progress}%` }}
            >
              <div className="absolute ltr:left-full ltr:-translate-x-1/2 rtl:left-0 inline-block px-2 py-0.5 text-[10px] text-white bg-mb-blue rounded -top-6 after:absolute after:border-4 ltr:after:right-1/2 rtl:after:left-1/2 after:-bottom-2 after:border-transparent after:border-t-mb-blue">
                {progress}%
              </div>
            </div>
          </div>
        )}
        <ul className="mb-0" id="dropzone-preview">
          {(selectedFiles || [])?.map((f: any, i: any) => {
            return (
              <li className="mt-1" id="dropzone-preview-list" key={i + "-file"}>
                <div className="border rounded border-slate-200 dark:border-zink-500">
                  <div className="flex flex-col lg:flex-row p-2 gap-4 items-center justify-start relative">
                    <div className="shrink-0 me-3">
                      <div
                        className={`p-0.5 rounded-md size-14 ${
                          meta.imageSizes.indexOf(f.ratio) > -1
                            ? "bg-mb-green"
                            : "bg-mb-red"
                        } relative`}
                      >
                        <img
                          data-dz-thumbnail
                          className="block w-full h-full rounded-md"
                          src={f.preview}
                          alt={f.name}
                        />
                        {meta.imageSizes.indexOf(f.ratio) > -1 ? (
                          <div className="bg-gray-200 rounded-full p-0.5 absolute right-0 bottom-0 z-10">
                            <Check size={15} className="text-green-600" />
                          </div>
                        ) : (
                          <div className="bg-gray-200 rounded-full p-0.5 absolute right-0 bottom-0 z-10">
                            <X size={15} className="text-mb-red" />
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="grow">
                      <div className="pt-1 flex flex-col items-start">
                        <h5 className="mb-1 text-15 truncate overflow-hidden max-w-[350px]" data-dz-name>
                          {f.name}
                        </h5>
                        <div className="flex gap-2 items-center">
                          <p className="p-1 bg-mb-red text-white">New</p>
                          {f.width} x {f.height} - ({f.ratio})
                          {meta.imageSizes.indexOf(f.ratio) > -1 ? (
                            <p className="font-bold text-green-700">
                              Aspect ratio matches at least one rail.
                            </p>
                          ) : (
                            <p className="font-bold text-mb-red">
                              Aspect ratio is not compatible with any rails
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="shrink-0 ms-3 absolute right-0 top-0 z-20">
                      {currentlyUploading === i ? (
                        <div className="inline-block size-8 border-2 rounded-full animate-spin border-l-transparent border-mb-blue"></div>
                      ) : (
                        <button
                          data-dz-remove
                          className="p-1 rounded-full text-xs text-white bg-mb-red border-mb-rebg-mb-red btn hover:text-white hover:bg-mb-red/20 hover:border-mb-red/20 focus:text-white focus:bg-mb-red/20 focus:border-mb-red/20 focus:ring focus:ring-red-100 active:text-white active:bg-mb-red/20 active:border-mb-red/20 active:ring active:ring-red-100 dark:ring-custom-400/20"
                          onClick={() => {
                            const newImages = [...selectedFiles];
                            newImages.splice(i, 1);
                            setSelectedFiles(newImages);
                          }}
                        >
                          <X size={10} />
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </li>
            );
          })}
          {(formData[name] || [])?.map((f: any, i: any) => {
            return (
              <li
                className="mt-1 bg-gray-100"
                id="dropzone-preview-list"
                key={i + "-file"}
              >
                <div className="border rounded border-slate-200 dark:border-zink-500">
                  <div className="flex flex-col lg:flex-row p-2 gap-4 items-center justify-start relative pt-8">
                    <div className="shrink-0 me-3">
                      <div
                        className={`p-2 rounded-md size-14 ${
                          meta.imageSizes.indexOf(f.ratio) > -1
                            ? "bg-mb-green"
                            : "bg-mb-red"
                        } relative`}
                      >
                        <img
                          data-dz-thumbnail
                          className="block w-full h-full rounded-md"
                          src={process.env.REACT_APP_IMAGE_CDN + f.path}
                          alt={"image title"}
                        />
                        {meta.imageSizes.indexOf(f.ratio) > -1 ? (
                          <div className="bg-gray-200 rounded-full p-0.5 absolute right-0 bottom-0 z-10">
                            <Check size={15} className="text-green-600" />
                          </div>
                        ) : (
                          <div className="bg-gray-200 rounded-full p-0.5 absolute right-0 bottom-0 z-10">
                            <X size={15} className="text-mb-red" />
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="grow">
                      <div className="pt-1 flex flex-col items-start">
                        <h5 className="mb-1 text-15" data-dz-name>
                          {getBaseName(f.path)}
                        </h5>
                        <div className="flex gap-2 items-center">
                          <p className="p-1 bg-mb-blue text-white">Uploaded</p>
                          {f.width} x {f.height} - ({f.ratio})
                        </div>
                      </div>
                    </div>
                    <div className="shrink-0 ms-3">
                      <div className="">
                        <h6 className="mb-1 text-15">Active</h6>
                        <Toggle defaultChecked checked={f.active} unCheckedChildren={"False"} checkedChildren={"True"} onChange={(checked:boolean)=>handleChange(f,checked?{value:true,label:"True"}:{value:false,label:'false'},"active")} />
                      </div>
                    </div>
                    <div className="shrink-0 ms-3">
                    
                      <div className="">
                        <h6 className="mb-1 text-15">Select Language</h6>
                        <CreatableSelect
                          className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-mb-blue disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                          id="choices-single-no-search"
                          name="choices-single-no-search"
                          isClearable
                          isSearchable={true}
                          options={options}
                          onChange={(selectedOption: Option | null) => {
                            handleChange(f, selectedOption,'language');
                          }}
                          value={
                            options.find(
                              (option) => option.value === f.language
                            ) || null
                          }
                        />
                      </div>
                    </div>
                    <div className="shrink-0 ms-3">
                      <div className="">
                        <h6 className="mb-1 text-15">Select PG Rating</h6>
                        <CreatableSelect
                          className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-mb-blue disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                          id="choices-single-no-search"
                          name="choices-single-no-search"
                          isClearable={false}
                          isSearchable={false}
                          options={[{value:"U",label:"U"},{value:"UA",label:"UA"},{value:"A",label:"A"},{value:"R",label:"R"}]}
                          onChange={(selectedOption: Option | null) => {
                            handleChange(f, selectedOption,'pgRating');
                          }}
                          value={
                            [{value:"U",label:"U"},{value:"UA",label:"UA"},{value:"A",label:"A"},{value:"R",label:"R"}].find(
                              (option) => option.value === f.pgRating
                            ) || null
                          }
                        />
                      </div>
                    </div>
                    <div className="absolute right-0 top-0 z-20">
                      <button
                        data-dz-remove
                        className="p-1 text-xs text-white bg-mb-red border-mb-rebg-mb-red btn hover:text-white hover:bg-mb-red/20 hover:border-mb-red/20 focus:text-white focus:bg-mb-red/20 focus:border-mb-red/20 focus:ring focus:ring-red-100 active:text-white active:bg-mb-red/20 active:border-mb-red/20 active:ring active:ring-red-100 dark:ring-custom-400/20 rounded-full"
                        onClick={() => {
                          const newImages = [...formData[name]];
                          newImages.splice(i, 1);
                          let newFormValues = { ...formData };
                          //setValueToForm(field, newImages);
                          newFormValues[name] = newImages;
                           setFormData({ ...newFormValues });
                        }}
                      >
                        <X size={10} />
                      </button>
                    </div>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default ImageUploader;
