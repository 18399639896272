import {
  getMetaTemplateData,
  getMetaUiData,
  addTitleInContent,
  assetByPageNumber,
  updateAssetInformation,
} from "helpers/mb-api_helper";
import React, { useCallback, useEffect, useState } from "react";
import {
  Link,
  Router,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  capitalizeFirstLetter,
  formatDate,
} from "coustumFuntions/capitalFirstLetter";
import BreadCrumb from "Common/BreadCrumb";
import {
  ArrowDownUp,
  BadgeCheck,
  BadgeX,
  ChevronLeft,
  ChevronRight,
  ChevronsLeft,
  ChevronsRight,
  Edit3Icon,
  Plus,
  Settings,
  Trash2Icon,
  X,
} from "lucide-react";
import MbLoader from "Common/Components/Icons/MbLoader";
import { Popup } from "reactjs-popup";
import Alert from "Common/Components/Alert";
import MbTitle from "components/commonComponents/MbTitle";
import { createSelector } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";

interface AnyObject {
  [key: string]: any; // Allows any key-value pairs in the user object
}

const AssetsListingPage = () => {
  let selectProperties = createSelector(
    (state: any) => state.User,
    (user) => ({
      meta: user.meta,
    })
  );
  const [searchParams, setSearchParams] = useSearchParams();
  let { meta } = useSelector(selectProperties);
  const [openAddPopup, setOpenAddPopup] = useState(false);
  const [openPublishPopup, setOpenPublishPopup] = useState(false);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [allAssets, setAllAssets] = useState<any>([]);
  const [searchTerm, setSearchTerm] = useState(searchParams.get("id") || "");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [searchTermDebounced, setSearchTermDebounced] = useState("");
  const [loading, setLoading] = useState(true);
  const [sort, setSort] = useState("v_tt:desc");
  const [assetTemplate, setAssetTemplate] = useState<AnyObject>({});
  const [assetUI, setAssetUI] = useState<AnyObject>({});
  const [currentPage, setCurrentPage] = useState(1);
  const [initialHeaders, setInitialHeaders] = useState<string[]>([]);
  const [totalAssets, setTotalAssets] = useState(0);
  const [pagination, setPagination] = useState<AnyObject>({
    size: 20,
    count: 1,
    from: 1,
    to: 20,
  });
  const [createTitle, setCreateTitle] = useState("");
  const [createError, setCreateError] = useState("");
  const { type } = useParams<{ type: string }>(); // Destructure `type` from useParams

  //Load Template & Meta of UI
  useEffect(() => {
    const getData = async () => {
      let metaUIRes: any = await getMetaUiData(type);
      let metaTemplateRes: any = await getMetaTemplateData(type);
      setAssetUI(metaUIRes);
      setInitialHeaders(metaUIRes.headers);
      setAssetTemplate(metaTemplateRes);
    };
    setSearchTerm(searchParams.get("id") || "");
    getData();
  }, [type]);

  ///Used for pagination, sort, search & initial load
  useEffect(() => {
    const getAllData = async () => {
      setLoading(true);
      try {
        let assetsRes: any = await assetByPageNumber(
          type,
          currentPage,
          searchTerm,
          sort
        );
        setTotalAssets(assetsRes.total);
        let pages = Math.ceil(assetsRes.total / pagination.size);
        let f = (currentPage - 1) * 20 + 1;
        let t = currentPage * 20;
        if (assetsRes.total < t) t = assetsRes.total;
        setPagination({ ...pagination, count: pages, from: f, to: t });
        setAllAssets(assetsRes.list);
        setLoading(false);
        setError("");
      } catch (e) {
        setError(
          `There was an error getting the listing of ${type}. Please try again.`
        );
        setPagination({ size: 0, count: 0, from: 0, to: 0 });
        // setSort("")
        // setCurrentPage(1)
        setLoading(false);
      }
    };
    if (assetUI.id && assetTemplate.id) {
      getAllData();
    }
  }, [sort, currentPage, assetUI, assetTemplate, searchTerm]);

  ////Search Term Debounce
  useEffect(() => {
    let timeout = setTimeout(() => {
      setSearchTermDebounced(searchTerm);
    }, 1000);
    return () => clearTimeout(timeout);
  }, [searchTerm]);

  //Handle Header Changes
  const manageHeaders = (e: any) => {
    if (e.target.checked) {
      setInitialHeaders([...initialHeaders, e.target.name]);
    } else {
      setInitialHeaders(initialHeaders.filter((h) => h !== e.target.name));
    }
  };

  const createAsset = useCallback(async () => {
    if (createTitle !== "") {
      setLoading(true);
      try {
        let createResult: any = await addTitleInContent(type, {
          title: createTitle,
          v_status: "draft",
        });
        setAllAssets([{ ...createResult }, ...allAssets]);
        setOpenAddPopup(false);
        setCreateTitle("");
        setSuccess(`Asset -${createResult.title} Created Successfully`);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        setError("Unable to create asset. Please try again!");
      }
    } else {
      setCreateError("Please enter title");
    }
  }, [allAssets, createTitle, type]);
  const managePublishing = useCallback(
    async (isPublished: boolean, id: string) => {
      setLoading(true);
      try {
        console.log(isPublished, id);
        let publishRes: any;
        if (isPublished)
          publishRes = await updateAssetInformation(id, type, {
            v_status: "PUBLISHED",
            v_published: true,
          });
        else
          publishRes = await updateAssetInformation(id, type, {
            v_status: "DRAFT",
            v_published: false,
          });
        console.log("publishRes", publishRes);
        setSuccess("Asset published successfully!");
        setLoading(false);
        setAllAssets([{ ...publishRes }, ...allAssets]);
      } catch (e) {
        console.log(e);
        setError("There was a problem publishing asset.");
      }
    },
    [type]
  );
  return (
    <React.Fragment>
      <div className="w-full min-h-screen flex flex-col text-xs">
        <BreadCrumb
          title={`Manage ${capitalizeFirstLetter(type?.replace("_", " "))
            .replace(/([A-Z])/g, " $1")
            .trim()}`}
          pageTitle="Asset Management"
        />

        <div className="card">
          <div className="card-body">
            <div className="search-filters card p-4 flex items-center justify-between gap-4">
              <input
                type="text"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                name="search"
                placeholder="Search or Filter anything..."
                className="h-8 w-1/2 px-4 border border-gray-300 rounded-md grow-0"
              />
              {Object.keys(assetUI).length > 0 &&
                Object.keys(assetTemplate).length > 0 && (
                  <div className="flex flex-row gap-4 justify-end items-center">
                    <Popup
                      arrow
                      on={"click"}
                      position={"bottom right"}
                      trigger={
                        <ArrowDownUp
                          size={15}
                          className="text-mb-blue cursor-pointer"
                        ></ArrowDownUp>
                      }
                    >
                      <div className="w-[200px] flex flex-col gap-0 border border-gray-300 bg-white mt-2 rounded-md overflow-hidden">
                        <div className="bg-mb-blue text-white py-1 px-2 font-bold">
                          Choose Sort Order
                        </div>
                        {assetUI.sortable.map((sortField: any, i: number) => {
                          return (
                            <div key={i}>
                              <div className="flex items-center gap-4  px-4 py-1">
                                <input
                                  onChange={(e) =>
                                    e.target.checked
                                      ? setSort(`${sortField}:asc`)
                                      : setSort("")
                                  }
                                  checked={sort === `${sortField}:asc`}
                                  type="checkbox"
                                  id={`sortable-asc-${sortField}`}
                                />
                                <label htmlFor={`sortable-asc-${sortField}`}>
                                  {assetTemplate.properties[sortField].name} ASC
                                </label>
                              </div>
                              <div className="flex items-center gap-4  px-4 py-1">
                                <input
                                  onChange={(e) =>
                                    e.target.checked
                                      ? setSort(`${sortField}:desc`)
                                      : setSort("")
                                  }
                                  checked={sort === `${sortField}:desc`}
                                  type="checkbox"
                                  id={`sortable-desc-${sortField}`}
                                />
                                <label htmlFor={`sortable-desc-${sortField}`}>
                                  {assetTemplate.properties[sortField].name}{" "}
                                  Desc
                                </label>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </Popup>
                    <Popup
                      trigger={
                        <Settings
                          size={15}
                          className="text-mb-blue cursor-pointer"
                        />
                      }
                      arrow
                      on={"click"}
                      position={"bottom right"}
                    >
                      <div className="w-[200px] flex flex-col gap-0 border border-gray-300 bg-white mt-2 rounded-md overflow-hidden">
                        <div className="bg-mb-blue text-white py-1 px-2 font-bold">
                          Choose Columns
                        </div>
                        {assetUI.headers.map((header: string, i: number) => {
                          return (
                            <div
                              key={`${i}-head`}
                              className="flex gap-2 items-center px-4 py-1"
                            >
                              <input
                                id={header}
                                type="checkbox"
                                name={header}
                                checked={initialHeaders.indexOf(header) > -1}
                                disabled
                              />
                              <label
                                className="cursor-pointer"
                                htmlFor={header}
                              >
                                {assetTemplate.properties[header].name}
                              </label>
                            </div>
                          );
                        })}
                        {assetUI.optionalHeader.map(
                          (header: string, i: number) => {
                            return (
                              <div
                                key={`${i}-opt-head`}
                                className="flex gap-2 items-center px-4 py-1"
                              >
                                <input
                                  id={header}
                                  onChange={manageHeaders}
                                  checked={initialHeaders.indexOf(header) > -1}
                                  name={header}
                                  type="checkbox"
                                />
                                <label
                                  className="cursor-pointer"
                                  htmlFor={header}
                                >
                                  {assetTemplate.properties[header].name}
                                </label>
                              </div>
                            );
                          }
                        )}
                      </div>
                    </Popup>
                    <button
                      onClick={() => setOpenAddPopup(true)}
                      className="bg-mb-blue rounded-md text-white hover:bg-mb-blue/30 hover:text-mb-blue px-4 py-2 flex items-center gap-1"
                    >
                      Add New <Plus size={10} />{" "}
                    </button>
                    <Popup
                      modal
                      open={openAddPopup}
                      onClose={() => setOpenAddPopup(false)}
                      overlayStyle={{
                        background: "rgba(0,0,0,0.7)",
                        zIndex: 9999999999999,
                      }}
                      lockScroll
                      closeOnDocumentClick
                    >
                      <div className="bg-white flex flex-col rounded-md md:w-[30rem]">
                        <div className="flex py-2 px-4 border-b border-b-mb-blue/50 justify-between items-center">
                          <h6>Add New</h6>
                          <button onClick={() => setOpenAddPopup(false)}>
                            <X size={15} />
                          </button>
                        </div>
                        <div className="flex flex-col gap-2 px-4 py-8">
                          {createError && (
                            <p className="text-xs text-mb-red">{createError}</p>
                          )}
                          <label htmlFor="addTitle">Title</label>
                          <input
                            value={createTitle}
                            onChange={(e) => setCreateTitle(e.target.value)}
                            type="text"
                            className="px-1 py-2 h-10 border border-mb-blue rounded-md"
                          />
                        </div>
                        <div className="flex py-2 px-4 border-t border-t-mb-blue/50 justify-end gap-4 items-center">
                          <button
                            className="bg-mb-red text-white rounded-md hover:bg-mb-red/30 hover:text-mb-red px-4 py-2 flex items-center gap-1"
                            onClick={() => setOpenAddPopup(false)}
                          >
                            Cancel
                          </button>
                          <button
                            className="bg-mb-blue text-white rounded-md hover:bg-mb-blue/30 hover:text-mb-blue px-4 py-2 flex items-center gap-1"
                            onClick={createAsset}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </Popup>
                  </div>
                )}
            </div>
            <div className="errors success">
              {success && (
                <Alert className="relative px-4 py-3 text-sm text-green-500 border border-transparent rounded-md bg-green-50 dark:bg-green-400/20">
                  <Alert.Close className="absolute top-0 bottom-0 right-0 p-3 transition text-custom-200 hover:text-green-500 dark:text-custom-400/50 dark:hover:text-custom-500" />
                  <Alert.Bold>Success! </Alert.Bold>
                  {success}
                </Alert>
              )}
              {error && (
                <Alert className="relative px-4 py-3 text-sm text-orange-500 border border-transparent rounded-md bg-orange-50 dark:bg-orange-400/20">
                  <Alert.Close className="absolute top-0 bottom-0 right-0 p-3 transition text-custom-200 hover:text-orange-500 dark:text-custom-400/50 dark:hover:text-custom-500" />
                  <Alert.Bold>Error! </Alert.Bold>
                  {error}
                </Alert>
              )}
            </div>
            {!loading && (
              <div className="overflow-x-auto border border-slate-200 rounded-md">
                <table className="w-full">
                  <thead className="ltr:text-left rtl:text-right ">
                    <tr>
                      {initialHeaders.map((header: any, i: number) => (
                        <th
                          key={i}
                          className="px-3.5 min-w-[200px] py-2.5 font-semibold border-b border-slate-200 dark:border-zink-500"
                        >
                          {assetTemplate.properties[header].name}
                        </th>
                      ))}
                      <th className="px-3.5 sticky right-0 min-w-[100px] py-2.5 font-semibold border-b border-slate-200 dark:border-zink-500 text-right bg-white">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {allAssets.map((asset: any, i: number) => {
                      return (
                        <tr
                          key={asset.id}
                          className="even:bg-slate-50 hover:bg-slate-50 even:hover:bg-slate-100 dark:even:bg-zink-600/50 dark:hover:bg-zink-600 dark:even:hover:bg-zink-600"
                        >
                          {initialHeaders.map((header: any, j: number) => {
                            return (
                              <td
                                key={asset.id + j}
                                className="px-3.5 py-2.5 border-y border-slate-200 dark:border-zink-500"
                              >
                                {(j === 0 || header === "id") &&
                                header !== "title" ? (
                                  <Link
                                    className="transition-all duration-150 ease-linear text-mb-blue hover:text-mb-blue/60"
                                    to={`/assets/${type}/${asset.id}`}
                                  >
                                    {asset[header]}
                                  </Link>
                                ) : header === "v_tt" ? (
                                  formatDate(asset[header]).toString()
                                ) : header === "v_status" ? (
                                  <span
                                    className={`${
                                      asset[header] === "draft"
                                        ? "bg-gray-200"
                                        : "bg-mb-green"
                                    } p-1`}
                                  >
                                    {asset[header].toUpperCase()}
                                  </span>
                                ) : header === "title" ? (
                                  <MbTitle
                                    type={type ? type : ""}
                                    title={asset[header]}
                                    image={
                                      asset.images
                                        ? `${process.env.REACT_APP_IMAGE_CDN}${asset?.images[0]?.path}`
                                        : ""
                                    }
                                    id={asset["id"]}
                                  />
                                ) : header === "catogory" ||
                                  header === "genres" ? (
                                  asset[header]?.join(", ")
                                ) : (
                                  asset[header]
                                )}
                              </td>
                            );
                          })}
                          <td className="px-3.5 py-2.5 sticky right-0 border z-50 bg-white border-slate-200 dark:border-zink-500">
                            <div className="w-full flex gap-2 justify-end">
                              <div>
                                <Link
                                  to={`/assets/${type}/${asset.id}`}
                                  className="w-auto py-0.5 font-bold text-mb-blue"
                                >
                                  <Edit3Icon
                                    className="inline-block mr-2"
                                    size={15}
                                  />
                                </Link>
                              </div>
                              <div>
                                {asset.v_status.toUpperCase() === "DRAFT" ? (
                                  <>
                                    <button
                                      onClick={() => setOpenPublishPopup(true)}
                                      className="w-auto py-0.5 font-bold text-mb-green"
                                    >
                                      <BadgeCheck
                                        className="inline-block mr-2"
                                        size={15}
                                      />
                                    </button>
                                  </>
                                ) : (
                                  <button className="w-auto py-0.5 font-bold text-mb-blue">
                                    <BadgeX
                                      className="inline-block mr-2"
                                      size={15}
                                      onClick={() => setOpenPublishPopup(true)}
                                    />
                                  </button>
                                )}
                                {assetUI.actions &&
                                  assetUI.actions.map(
                                    (act: string, i: number) => {
                                      return (
                                        <a
                                          key={i}
                                          href={`/assets/${act}?id="${asset.id}"`}
                                          className="w-auto py-0.5 font-bold text-mb-blue mr-2"
                                        >
                                          {capitalizeFirstLetter(act)}
                                        </a>
                                      );
                                    }
                                  )}
                                <Popup
                                  modal
                                  open={openPublishPopup}
                                  onClose={() => setOpenPublishPopup(false)}
                                  overlayStyle={{
                                    background: "rgba(0,0,0,0.1)",
                                    zIndex: 9999999999999,
                                  }}
                                  lockScroll
                                  closeOnDocumentClick
                                >
                                  <div className="bg-white flex flex-col rounded-md md:w-[30rem]">
                                    <div className="flex py-2 px-4 border-b border-b-mb-blue/50 justify-between items-center">
                                      <h6>
                                        {asset.v_published
                                          ? "Unpublish?"
                                          : "Publish?"}
                                      </h6>
                                      <button
                                        onClick={() =>
                                          setOpenPublishPopup(false)
                                        }
                                      >
                                        <X size={15} />
                                      </button>
                                    </div>
                                    <div className="flex flex-col gap-2 px-4 py-8">
                                      <label htmlFor="addTitle">
                                        Are you sure you want to{" "}
                                        {asset.v_published ? "Unp" : "P"}ublish
                                        - {asset.title}
                                      </label>
                                    </div>
                                    <div className="flex py-2 px-4 border-t border-t-mb-blue/50 justify-end gap-4 items-center">
                                      <button
                                        className="bg-mb-red text-white rounded-md hover:bg-mb-red/30 hover:text-mb-red px-4 py-2 flex items-center gap-1"
                                        onClick={() =>
                                          setOpenPublishPopup(false)
                                        }
                                      >
                                        Cancel
                                      </button>
                                      <button
                                        className="bg-mb-blue text-white rounded-md hover:bg-mb-blue/30 hover:text-mb-blue px-4 py-2 flex items-center gap-1"
                                        onClick={() =>
                                          managePublishing(
                                            asset.v_published,
                                            asset.id
                                          )
                                        }
                                      >
                                        Yes
                                      </button>
                                    </div>
                                  </div>
                                </Popup>
                              </div>
                              <div>
                                <button className="w-auto py-0.5 font-bold text-mb-red">
                                  <Trash2Icon
                                    className="inline-block mr-2"
                                    size={15}
                                  />
                                </button>
                              </div>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )}
            {pagination.count > 0 && (
              <div className="pagination flex items-center justify-between py-4">
                <div className="flex flex-col gap-2">
                  <p className="">
                    Showing From {pagination.from} to {pagination.to} of{" "}
                    {totalAssets}
                  </p>
                  <div className="flex gap-2">
                    <p className="">Go to Page</p>
                    <select
                      className=""
                      onChange={(e: any) => setCurrentPage(e.target.value)}
                    >
                      {[...Array(pagination.count).keys()].map((int) => (
                        <option
                          key={`page-${int}`}
                          selected={currentPage === int + 1}
                          value={int + 1}
                        >
                          {int + 1}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <ul className="flex flex-wrap items-center gap-2">
                  {currentPage > 1 && (
                    <li>
                      <button
                        onClick={() => setCurrentPage(1)}
                        className="inline-flex items-center justify-center bg-white size-8 transition-all duration-150 ease-linear border rounded border-slate-200 text-slate-500 hover:text-custom-500 hover:bg-custom-50 focus:bg-custom-50 focus:text-custom-500 [&.active]:text-custom-50 [&.active]:bg-custom-500 [&.active]:border-custom-500 [&.disabled]:text-slate-400 [&.disabled]:cursor-auto"
                      >
                        <ChevronsLeft className="size-4 rtl:rotate-180" />
                      </button>
                    </li>
                  )}
                  {currentPage > 1 && (
                    <li>
                      <button
                        onClick={() => setCurrentPage(currentPage - 1)}
                        className="inline-flex items-center justify-center bg-white size-8 transition-all duration-150 ease-linear border rounded border-slate-200 text-slate-500 hover:text-custom-500 hover:bg-custom-50 focus:bg-custom-50 focus:text-custom-500 [&.active]:text-custom-50 [&.active]:bg-custom-500 [&.active]:border-custom-500 [&.disabled]:text-slate-400 [&.disabled]:cursor-auto"
                      >
                        <ChevronLeft className="size-4 rtl:rotate-180" />
                      </button>
                    </li>
                  )}
                  {[...Array(pagination.count).keys()].map((int) => {
                    return (
                      int + 1 >= currentPage - 1 &&
                      int + 1 <= currentPage + 1 && (
                        <li className={currentPage === int + 1 ? "active" : ""}>
                          <button
                            onClick={() => setCurrentPage(int + 1)}
                            className={`${
                              currentPage === int + 1 ? "active" : ""
                            } inline-flex items-center justify-center bg-white size-8 transition-all duration-150 ease-linear border rounded border-slate-200 text-slate-500 hover:text-custom-500 hover:bg-custom-50 focus:bg-custom-50 focus:text-custom-500 [&.active]:text-custom-50 [&.active]:bg-custom-500 [&.active]:border-custom-500 [&.disabled]:text-slate-400 [&.disabled]:cursor-auto`}
                          >
                            {int + 1}
                          </button>
                        </li>
                      )
                    );
                  })}

                  {currentPage < pagination.count && (
                    <li>
                      <button
                        onClick={() => setCurrentPage(currentPage + 1)}
                        className="inline-flex items-center justify-center bg-white size-8 transition-all duration-150 ease-linear border rounded border-slate-200 text-slate-500 hover:text-custom-500 hover:bg-custom-50 focus:bg-custom-50 focus:text-custom-500 [&.active]:text-custom-50 [&.active]:bg-custom-500 [&.active]:border-custom-500 [&.disabled]:text-slate-400 [&.disabled]:cursor-auto"
                      >
                        <ChevronRight className="size-4 rtl:rotate-180" />
                      </button>
                    </li>
                  )}
                  {currentPage < pagination.count && (
                    <li>
                      <button
                        onClick={() => setCurrentPage(pagination.count)}
                        className="inline-flex items-center justify-center bg-white size-8 transition-all duration-150 ease-linear border rounded border-slate-200 text-slate-500 hover:text-custom-500 hover:bg-custom-50 focus:bg-custom-50 focus:text-custom-500 [&.active]:text-custom-50 [&.active]:bg-custom-500 [&.active]:border-custom-500 [&.disabled]:text-slate-400 [&.disabled]:cursor-auto"
                      >
                        <ChevronsRight className="size-4 rtl:rotate-180" />
                      </button>
                    </li>
                  )}
                </ul>
              </div>
            )}
          </div>
        </div>

        {loading && <MbLoader />}
      </div>
    </React.Fragment>
  );
};

export default AssetsListingPage;
