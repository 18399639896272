import { setEditContent, setEditContentError } from "./reducer";
import { ThunkAction } from "redux-thunk";
import { Action, Dispatch } from "redux";
import { RootState } from "slices";
import { getContent } from "helpers/mb-api_helper";

export const setFormEditData =
  (
    id: string | undefined,
    type: string | undefined,
    languages: string[]
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch: Dispatch) => {
    try {
      const response = await getContent(id, type);

      dispatch(setEditContent(response));
    } catch (e) {
      dispatch(setEditContentError({}));
    }
  };
