import BreadCrumb from "Common/BreadCrumb";
import { createMenuItem, getAllMenu } from "helpers/mb-api_helper";
import { Edit3Icon, Search } from "lucide-react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

const tableHeaders = ["menus", "placement"];

const MenuSection = () => {
  const selectUserMeta = (state: any) => state.User.meta;
  const meta = useSelector(selectUserMeta);
  const { id } = useParams();
  const navigate = useNavigate();
  const [tableRowData, setTableRowData] = useState([]);

  // Memoize handleCreateMenuItem
  const handleCreateMenuItem = useCallback(
    async (menuId: string) => {
      try {
        const res = await createMenuItem({
          id: menuId,
          title: "",
          placement: menuId,
          v_platform: id,
        });
        const data: any = res;
        navigate(`/platforms/menus/${id}/${data.id}`);
      } catch (err) {
        console.error(err); // Consider handling errors appropriately
      }
    },
    [id, navigate]
  ); // Add dependencies here

  // Memoize handleEditMenu
  const handleEditMenu = useCallback(
    async (menuId: string, itemId: string) => {
      if (itemId === "") {
        handleCreateMenuItem(menuId);
      } else {
        navigate(`/platforms/menus/${id}/${itemId}`);
      }
    },
    [id, handleCreateMenuItem, navigate]
  ); // Add dependencies here
  // menu placement action
  const columns: any = {
    menus: {
      header: "Menu",
      accessorKey: "ids",
      enableColumnFilter: false,
    },
    placement: {
      header: "Placement",
      accessorKey: "name",
      enableColumnFilter: false,
    },
  };

  // Memoize menuPlacementsData
  const menuPlacementsData = useMemo(() => {
    return (
      meta?.menuPlacements?.map((menu: string) => ({
        name: menu,
      })) || []
    );
  }, [meta?.menuPlacements]); // Add dependencies here
  useEffect(() => {
    const getAllMenuData = async () => {
      try {
        const response = await getAllMenu();

        const menuData: any = response;
        const placementsToIds: { [key: string]: string } = {};

        menuData.list.forEach((item: any) => {
          const key = `${item.v_platform}-${item.placement}`;
          if (!placementsToIds[key]) {
            placementsToIds[key] = "";
          }
          placementsToIds[key] = item.id;
        });

        const updatedFirstArray = menuPlacementsData?.map((item: any) => {
          const key = `${id}-${item.name}`; // Adjust as needed
          return {
            ...item,
            ids: placementsToIds[key] || "",
          };
        });

        setTableRowData(updatedFirstArray);
      } catch (err) {
        console.error("Error fetching menu data", err);
      }
    };

    getAllMenuData();
  }, [id, menuPlacementsData]);

  return (
    <React.Fragment>
      <BreadCrumb title={`Manage Views - ${id}`} pageTitle="" />
      {/* <ToastContainer closeButton={false} limit={1} /> */}
      <div className="text-xs grid grid-cols-1 gap-x-5 xl:grid-cols-12">
        <div className="xl:col-span-12">
          <div className="card" id="usersTable">
            <div className="card-body">
              {tableRowData && tableRowData?.length > 0 ? (
                <>
                  <div
                    className={`overflow-x-auto border border-slate-200 rounded-md`}
                  >
                    <table className="w-full">
                      <thead className="ltr:text-left rtl:text-right ">
                        <tr>
                          {tableHeaders.map((header: any, i: number) => (
                            <th
                              key={i}
                              className=" px-3.5 min-w-[200px] py-2.5 font-semibold border-b border-slate-200 dark:border-zink-500"
                            >
                              <p className="capitalize">{header}</p>
                            </th>
                          ))}
                          <th className="px-3.5 sticky right-0 min-w-[100px] py-2.5 font-semibold border-b border-slate-200 dark:border-zink-500 text-right bg-white">
                            Actions
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {tableRowData.map((view: any, i: number) => (
                          <tr
                            key={view.id + i}
                            className={` even:bg-slate-50 hover:bg-slate-50 even:hover:bg-slate-100 dark:even:bg-zink-600/50 dark:hover:bg-zink-600 dark:even:hover:bg-zink-600`}
                          >
                            {tableHeaders.map((header: any, j: number) => {
                              return (
                                <td
                                  key={header}
                                  className="px-3.5 py-2.5 border-b border-slate-200 dark:border-zink-500"
                                >
                                  <p>{view[columns[header].accessorKey]}</p>
                                </td>
                              );
                            })}
                            <td
                              className={
                                "px-3.5 py-2.5 sticky right-0 border z-50 bg-white border-slate-200 dark:border-zink-500"
                              }
                            >
                              <div className="w-full flex gap-2 justify-end items-center">
                                <div className="flex gap-2">
                                  <button
                                    // to={`/platforms/views/${id}/${view.id}`}
                                    onClick={() => {
                                      handleEditMenu(view.name, view.ids);
                                    }}
                                    className="w-auto py-0.5 font-bold text-mb-blue"
                                  >
                                    {view?.ids ? (
                                      <Edit3Icon
                                        className="inline-block mr-2"
                                        size={15}
                                      />
                                    ) : (
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="15"
                                        height="15"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      >
                                        <circle cx="12" cy="12" r="10" />
                                        <path d="M8 12h8" />
                                        <path d="M12 8v8" />
                                      </svg>
                                    )}
                                  </button>
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </>
              ) : (
                // <TableContainer
                //   isPagination={true}
                //   columns={columns || []}
                //   data={tableRowData || []}
                //   divclassName="-mx-5 -mb-5 overflow-x-auto"
                //   tableclassName="w-full border-separate table-custom border-spacing-y-1 whitespace-nowrap"
                //   theadclassName="text-left relative rounded-md bg-slate-100 dark:bg-zink-600 after:absolute ltr:after:border-l-2 rtl:after:border-r-2 ltr:after:left-0 rtl:after:right-0 after:top-0 after:bottom-0 after:border-transparent [&.active]:after:border-mb-blue [&.active]:bg-slate-100 dark:[&.active]:bg-zink-600"
                //   thclassName="px-3.5 py-2.5 first:pl-5 last:pr-5 font-semibold"
                //   tdclassName="px-3.5 py-2.5 first:pl-5 last:pr-5"
                //   PaginationClassName="flex flex-col items-center mt-8 md:flex-row"
                // />
                <div className="noresult">
                  <div className="py-6 text-center">
                    <Search className="size-6 mx-auto text-mb-blue fill-sky-100 dark:mb-blue/20" />
                    <h5 className="mt-2 mb-1">Sorry! No Result Found</h5>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default MenuSection;
