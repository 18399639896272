import { Label } from "@headlessui/react/dist/components/label/label";
import { createSelector } from "@reduxjs/toolkit";

import { slugify } from "helpers/utility";
import countries from "lib/countries";
import isoLangs from "lib/languages";
import { Info, X } from "lucide-react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Popup from "reactjs-popup";
import { SelectPicker, TagInput, TagPicker } from "rsuite";

interface InputProps {
  name: string;
  props: any;
  formData?: any;
  setFormData: React.Dispatch<React.SetStateAction<any>>;
  i18n?: string;
}

const SingleSelect: React.FC<InputProps> = ({
  name,
  props,
  formData = {},
  setFormData,
  i18n,
}) => {
  const [showHelper, setShowHelper] = useState(false);
  const [validValues, setValidValues] = useState<
    { value: string; label: string }[]
  >([]);
  let selectProperties = createSelector(
    (state: any) => state.User,
    (user) => ({
      meta: user.meta,
    })
  );
  let { meta } = useSelector(selectProperties);
  useEffect(() => {
    if (props.validValues) {
      setValidValues(
        props.validValues.map((v: string) => ({ value: v, label: v }))
      );
    } else if (
      props.validValuesStatic &&
      props.validValuesStatic === "languages"
    ) {
      setValidValues(
        Object.keys(isoLangs).map((k: string) => ({
          value: k,
          label: isoLangs[k].name,
        }))
      );
    } else if (
      props.validValuesStatic &&
      props.validValuesStatic === "countries"
    ) {
      setValidValues(
        Object.keys(countries).map((k: string) => ({
          value: k,
          label: countries[k],
        }))
      );
    }
  }, [props]);

  return (
    <div
      className={`flex flex-col gap-1 ${
        props.cols ? `lg:col-span-${props.cols}` : "lg:col-span-6"
      }`}
    >
      <div className="flex gap-1 items-center">
        <label className="text-sm">{props.name}</label>
        {props.longDesc && (
          <>
            <button
              className="text-mb-blue"
              onClick={() => setShowHelper(true)}
            >
              <Info size={15} />
            </button>
            <Popup
              open={showHelper}
              onClose={() => setShowHelper(false)}
              modal
              lockScroll
              closeOnDocumentClick
              contentStyle={{
                top: 0,
                right: 0,
                height: "100vh",
                position: "fixed",
              }}
              overlayStyle={{ background: "rgba(0,0,0,0.6)", zIndex: 999999 }}
            >
              <div className="h-screen bg-white w-[20rem] relative">
                <button
                  onClick={() => setShowHelper(false)}
                  className="absolute right-4 top-4"
                >
                  <X size={30} />
                </button>
                <div className="text-xl text-mb-blue border-b border-b-mb-blue font-bold p-4">
                  {props.longDesc.title}
                </div>
                <div
                  className="text-sm text-gray-900 p-4 flex flex-col gap-4"
                  dangerouslySetInnerHTML={{ __html: props.longDesc.body }}
                ></div>
              </div>
            </Popup>
          </>
        )}
      </div>
      <span className="text-xs">{props.shortDesc}</span>
      <SelectPicker
        disabled={i18n !== meta.lang}
        data={validValues}
        readOnly={props.editable ? !props.editable : false}
        className="border !border-mb-blue !text-black rounded-md w-full"
        onChange={(v, e) => setFormData({ ...formData, [name]: v })}
        value={formData[name] ? formData[name] : []}
      ></SelectPicker>
    </div>
  );
};

export default SingleSelect;
