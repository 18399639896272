import { AxiosResponse } from "axios";
import { getContent, searchAssetByType } from "helpers/mb-api_helper";
import RightDrawer from "Layout/RightDrawer";
import React, { useEffect, useRef, useState } from "react";

interface Component {
  field: string;
  formValues: { [key: string]: any };
  setFormValues: React.Dispatch<React.SetStateAction<{ [key: string]: any }>>;
  setValueToForm: (fieldName: string, value: any) => void;
  element: {
    shortDesc?: string;
    longDesc?: {
      title: string;
      body: string;
    };
    name: string;
    referenceType: string;
    referenceField: string;
    cols: number;
  };
}
interface RecordsData {
  [key: string]: any; // Allows any key-value pairs in the user object
}
interface Option {
  readonly label: string;
  readonly value: string;
  readonly isDisabled?: boolean;
}
const NewSearchableSelect: React.FC<Component> = ({
  element,
  field,
  formValues,
  setValueToForm,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [assetsOptions, setAssetsOption] = useState<any[]>([]);
  const [assetName, setAssetName] = useState<string>("");
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleChange = async (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setAssetName(e.target.value);
    try {
      const response: AxiosResponse<RecordsData> = await searchAssetByType(
        element.referenceType,
        e.target.value
      );
      const responseData: any = response;
      setAssetsOption(
        responseData.list?.map((data: any) => ({
          label: data.title,
          value: data[element.referenceField],
        }))
      );
      // Extract the data from the response and set it in the state
    } catch (error) {
      console.error("Error fetching assets:", error);
    }
  };

  const handleSelectChange = (option: Option) => {
    setAssetName(option.label);
    setValueToForm(field, option.value);
    // setFormValues((prevValues) => ({
    //   ...prevValues,
    //   [field]: option.value,
    // }));
    setIsDropdownOpen(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    //get the name from the id
    const getAssetNameById = async () => {
      try {
        const response: AxiosResponse<RecordsData> = await getContent(
          formValues[field],
          element.referenceType
        );
        const responseData: any = response;
        setAssetName(responseData.title);
      } catch (error) {
        console.error("Error fetching asset name:", error);
      }
    };
    if (formValues[field] !== "" && formValues[field] && assetName === "") {
      getAssetNameById();
    }
  }, [formValues, field, element.referenceType]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className={`lg:col-span-${element.cols || 6}`}>
      <div className="relative" ref={dropdownRef}>
        <div className="flex flex-col gap-1 mb-2">
          <div className="flex items-center gap-2">
            <label className="inline-block text-base font-medium">
              {element.name}
            </label>
            {element.longDesc && (
              <RightDrawer
                title={element.longDesc.title}
                body={element.longDesc.body}
              />
            )}
          </div>
          {element.shortDesc && element.shortDesc !== "" && (
            <span className="text-sm">{element.shortDesc}</span>
          )}
        </div>
        <input
          type="text"
          value={assetName || ""}
          onChange={handleChange}
          onFocus={() => setIsDropdownOpen(true)}
          name={element.name}
          className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-mb-blue disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
          placeholder={`Search ${element.referenceType}`}
        />
        {isDropdownOpen && (
          <div className="absolute top-full left-0 w-full flex flex-col gap-2 p-1 bg-white border border-slate-200 dark:bg-zink-700 dark:border-zink-500 z-10">
            {assetsOptions?.length === 0 && assetName !== "" && (
              <div className="w-full flex justify-center">
                <p className="p-1 truncate hover:bg-slate-200 dark:hover:bg-zink-600 cursor-pointer">
                  No Result Found
                </p>
              </div>
            )}
            {assetsOptions?.map((assetOption: Option) => (
              <p
                key={assetOption.value}
                className="p-1 truncate hover:bg-slate-200 dark:hover:bg-zink-600 cursor-pointer"
                onClick={() => {
                  handleSelectChange(assetOption);
                }}
              >
                {assetOption.label}
              </p>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default NewSearchableSelect;
