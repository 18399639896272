import RightDrawer from "Layout/RightDrawer";
import React, { useEffect, useRef, useState } from "react";

interface TimeComponent {
  hh: number | string;
  mm: number | string;
  sec: number | string;
}

interface Component {
  field: string;
  formValues: { [key: string]: any };
  setFormValues: React.Dispatch<React.SetStateAction<{ [key: string]: any }>>;
  setValueToForm: (field: string, value: any) => void;
  element: {
    shortDesc?: string;
    longDesc?: {
      title: string;
      body: string;
    };
    name: string;
    isMultiLingual: boolean;
    autoGenerate?: boolean;
    autoGenerateBy: string;
    cols: number;
  };
}

const StartEndTimeInputBox: React.FC<Component> = ({
  element,
  field,
  formValues,
  setValueToForm,
}) => {
  const [timeError, setTimeError] = useState({ message: "", active: false });
  const [startTime, setStartTime] = useState<TimeComponent>({
    hh: "",
    mm: "",
    sec: "",
  });
  const [endTime, setEndTime] = useState<TimeComponent>({
    hh: "",
    mm: "",
    sec: "",
  });

  const startHhRef = useRef<HTMLInputElement>(null);
  const startMmRef = useRef<HTMLInputElement>(null);
  const startSecRef = useRef<HTMLInputElement>(null);
  const endHhRef = useRef<HTMLInputElement>(null);
  const endMmRef = useRef<HTMLInputElement>(null);
  const endSecRef = useRef<HTMLInputElement>(null);

  const [chips, setChips] = useState<string[]>([]);

  const handleStartTimeChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    val: string
  ) => {
    const { value } = e.target;
    if (timeError.active) {
      setTimeError({ message: "", active: false });
    }
    if (/^\d*$/.test(value) && +value < 60)
      setStartTime((preVal) => {
        let newState = { ...preVal, [val]: value };
        if (val === "mm" && newState.hh === "") {
          newState = { ...preVal, [val]: value, hh: "00" };
        } else if (val === "sec" && newState.mm === "") {
          newState = { ...preVal, [val]: value, mm: "00" };
        }
        if (value.length === 2) {
          if (val === "hh" && startMmRef.current) {
            startMmRef.current.focus();
          } else if (val === "mm" && startSecRef.current) {
            startSecRef.current.focus();
          } else if (val === "sec" && endHhRef.current) {
            endHhRef.current.focus();
          }
        }

        return newState;
      });
  };

  const handleEndTimeChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    val: string
  ) => {
    const { value } = e.target;
    if (timeError.active) {
      setTimeError({ message: "", active: false });
    }
    if (val === "mm") {
      if (endTime.hh < startTime.hh) {
        setTimeError({
          message: "end time should be greater than start time",
          active: true,
        });
        return;
      }
    }
    if (val === "sec") {
      if (endTime.hh <= startTime.hh && endTime.mm < startTime.mm) {
        setTimeError({
          message: "end time should be greater than start time",
          active: true,
        });
        return;
      }
    }
    if (value.length === 2 && val === "sec") {
      if (
        endTime.hh <= startTime.hh &&
        endTime.mm <= startTime.mm &&
        value < startTime.sec
      ) {
        setTimeError({
          message: "end time should be greater than start time",
          active: true,
        });
        return;
      }
    }

    if (/^\d*$/.test(value) && +value < 60)
      setEndTime((preVal) => {
        const newState = { ...preVal, [val]: value };

        if (value.length === 2) {
          if (val === "hh" && endMmRef.current) {
            endMmRef.current.focus();
          } else if (val === "mm" && endSecRef.current) {
            endSecRef.current.focus();
          } else if (val === "sec" && startHhRef.current) {
            const timeString = `${startTime.hh} : ${startTime.mm} : ${startTime.sec} / ${endTime.hh} : ${endTime.mm} : ${value}`;
            if (!chips.includes(timeString)) {
              setChips([...chips, timeString]);
              setValueToForm(field, [...chips, timeString.trim()]);
              // setFormValues((prevValues) => ({
              //   ...prevValues,
              //   [field]: [...chips, timeString.trim()],
              // }));
              setStartTime({
                hh: "",
                mm: "",
                sec: "",
              });
              setEndTime({
                hh: "",
                mm: "",
                sec: "",
              });
            }
            startHhRef.current.focus();
          }
        }

        return newState;
      });
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && startHhRef.current) {
      e.preventDefault();
      const keys: (keyof typeof startTime)[] = ["hh", "mm", "sec"];
      if (keys.some((key) => startTime[key] === "" || endTime[key] === "")) {
        setTimeError({
          message: "Fill all fields with some values",
          active: true,
        });
        return;
      }
      const timeString = `${startTime.hh} : ${startTime.mm} : ${startTime.sec} / ${endTime.hh} : ${endTime.mm} : ${endTime.sec}`;
      if (!chips.includes(timeString)) {
        setChips([...chips, timeString]);
        setValueToForm(field, [...chips, timeString.trim()]);
        // setFormValues((prevValues) => ({
        //   ...prevValues,
        //   [field]: [...chips, timeString.trim()],
        // }));
        setStartTime({
          hh: "",
          mm: "",
          sec: "",
        });
        setEndTime({
          hh: "",
          mm: "",
          sec: "",
        });
        startHhRef.current.focus();
      } else {
        setTimeError({
          message: "This time already added",
          active: true,
        });
      }
    }
  };

  const handleDeleteChip = (chipToDelete: string) => {
    setChips(chips.filter((chip) => chip !== chipToDelete));
    setValueToForm(field, [chips.filter((chip) => chip !== chipToDelete)]);
    // setFormValues((prevValues) => ({
    //   ...prevValues,
    //   [field]: [chips.filter((chip) => chip !== chipToDelete)],
    // }));
  };

  useEffect(() => {
    if (formValues[field] && formValues[field].length > 0) {
      setChips(formValues[field]);
    } else {
      setChips([]);
    }
  }, [formValues[field]]);

  return (
    <div className={`lg:col-span-${element.cols || 6}`}>
      <div className="flex flex-col gap-1 mb-2">
        <div className="flex items-center gap-2">
          <label className="inline-block text-base font-medium">
            {element.name}
          </label>
          {element.longDesc && (
            <RightDrawer
              title={element.longDesc.title}
              body={element.longDesc.body}
            />
          )}
        </div>
        {element.shortDesc && element.shortDesc !== "" && (
          <span className="text-sm">{element.shortDesc}</span>
        )}
      </div>
      <div className=" flex flex-wrap gap-4 border items-baseline border-gray-300 rounded-lg p-2">
        <div className="flex flex-wrap gap-3 items-center">
          {chips.map((chip, index) => (
            <div
              key={index}
              className="flex items-center bg-mb-blue text-white text-sm px-2 py-1 rounded-lg"
            >
              {chip}
              <button
                onClick={() => handleDeleteChip(chip)}
                className="ml-1 text-white hover:text-gray-200 focus:outline-none"
              >
                &times;
              </button>
            </div>
          ))}
        </div>
        <div className="">
          <div className="flex gap-2 items-center flex-wrap">
            <div className="flex gap-2">
              <input
                ref={startHhRef}
                type="text"
                value={startTime.hh}
                onChange={(e) => handleStartTimeChange(e, "hh")}
                onKeyDown={handleKeyDown}
                placeholder="00"
                className="w-5 outline-none border-none"
              />
              <p> : </p>
              <input
                ref={startMmRef}
                type="text"
                value={startTime.mm}
                onChange={(e) => handleStartTimeChange(e, "mm")}
                onKeyDown={handleKeyDown}
                placeholder="00"
                className="w-5 outline-none border-none"
              />
              <p> : </p>
              <input
                ref={startSecRef}
                type="text"
                value={startTime.sec}
                onChange={(e) => handleStartTimeChange(e, "sec")}
                onKeyDown={handleKeyDown}
                placeholder="00"
                className="w-5 outline-none border-none"
              />
            </div>
            <p> / </p>
            <div className="flex gap-2">
              <input
                ref={endHhRef}
                type="text"
                value={endTime.hh}
                onChange={(e) => handleEndTimeChange(e, "hh")}
                onKeyDown={handleKeyDown}
                placeholder="00"
                className="w-5 outline-none border-none"
              />
              <p> : </p>
              <input
                ref={endMmRef}
                type="text"
                value={endTime.mm}
                onChange={(e) => handleEndTimeChange(e, "mm")}
                onKeyDown={handleKeyDown}
                placeholder="00"
                className="w-5 outline-none border-none"
              />
              <p> : </p>
              <input
                ref={endSecRef}
                type="text"
                value={endTime.sec}
                onChange={(e) => handleEndTimeChange(e, "sec")}
                onKeyDown={handleKeyDown}
                placeholder="00"
                className="w-5 outline-none border-none"
              />
            </div>
          </div>
          {timeError.active && (
            <p className="text-sm text-red-600 mb-2">{timeError.message}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default StartEndTimeInputBox;
