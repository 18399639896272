import React, { useEffect, useState } from "react";
import Select, { MultiValue, ActionMeta, StylesConfig } from "react-select";
import countries from "lib/countries";
import isoLangs from "lib/languages";
import RightDrawer from "Layout/RightDrawer";

interface Option {
  readonly label: string;
  readonly value: string;
  readonly isDisabled?: boolean;
}

interface Component {
  field: string;
  formValues: { [key: string]: any };
  setFormValues: React.Dispatch<React.SetStateAction<{ [key: string]: any }>>;
  setValueToForm: (fieldName: string, value: any) => void;
  element: {
    shortDesc?: string;
    longDesc?: {
      title: string;
      body: string;
    };
    name: string;
    validValues?: string[];
    validValuesStatic?: "languages" | "countries";
    cols: number;
  };
}

const MultiSelectBox: React.FC<Component> = ({
  element,
  field,
  formValues,
  setValueToForm,
}) => {
  const [options, setOptions] = useState<Option[]>([]);

  const colourStyles: StylesConfig<any, true> = {
    control: (styles) => ({ ...styles, backgroundColor: "white" }),
    multiValue: (styles) => {
      return {
        ...styles,
        backgroundColor: "#457abd",
      };
    },
    multiValueLabel: (styles) => ({
      ...styles,
      color: "white",
    }),
  };

  useEffect(() => {
    if (element.validValues) {
      setOptions(element.validValues.map((v) => ({ value: v, label: v })));
    } else if (element.validValuesStatic) {
      switch (element.validValuesStatic) {
        case "languages":
          setOptions(
            Object.keys(isoLangs).map((key) => ({
              value: key,
              label: isoLangs[key].name,
            }))
          );
          break;
        case "countries":
          setOptions(
            Object.keys(countries).map((key) => ({
              value: key,
              label: countries[key as keyof typeof countries],
            }))
          );
          break;
        default:
          break;
      }
    }
  }, [element]);

  const handleChange = (
    newValue: MultiValue<Option>,
    actionMeta: ActionMeta<Option>
  ) => {
    const value = newValue
      ? (newValue as Option[]).map((option) => option.value)
      : [];
    setValueToForm(field, value);
    // setFormValues((prevValues) => ({
    //   ...prevValues,
    //   [field]: newValue
    //     ? (newValue as Option[]).map((option) => option.value)
    //     : [],
    // }));
  };

  return (
    <div className={`lg:col-span-${element.cols || 6}`}>
      <div className="">
        <div className="flex flex-col gap-1 mb-2">
          <div className="flex items-center gap-2">
            <label className="inline-block text-base font-medium">
              {element.name}
            </label>
            {element.longDesc && (
              <RightDrawer
                title={element.longDesc.title}
                body={element.longDesc.body}
              />
            )}
          </div>
          {element.shortDesc && element.shortDesc !== "" && (
            <span className="text-sm">{element.shortDesc}</span>
          )}
        </div>
        <Select
          styles={colourStyles}
          className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-mb-blue disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
          id="choices-multiple-remove-button"
          name="choices-multiple-remove-button"
          value={formValues[field]?.map((value: string) =>
            options.find((data) => data.value === value)
          )}
          isClearable
          isMulti
          isSearchable
          options={options}
          onChange={handleChange}
        />
      </div>
    </div>
  );
};

export default MultiSelectBox;
