import { createSelector } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { assetByPageNumber, getContent, searchAssetByType } from "helpers/mb-api_helper";
import RightDrawer from "Layout/RightDrawer";
import { Info, X } from "lucide-react";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import Popup from "reactjs-popup";

interface InputProps {
  name:string  
  props:any
  formData?: any;
  setFormData: React.Dispatch<React.SetStateAction<any>>
  i18n:string
}
interface RecordsData {
  [key: string]: any; // Allows any key-value pairs in the user object
}
interface Option {
  readonly label: string;
  readonly value: string;
  readonly isDisabled?: boolean;
}
const AssetSelector: React.FC<InputProps> = ({name, props, formData={}, setFormData,i18n }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [assetsOptions, setAssetsOption] = useState<any[]>([]);
  const [assetName, setAssetName] = useState<string>("");
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [showHelper,setShowHelper] = useState(false)
  let selectProperties = createSelector(
    (state: any) => state.User,
    (user) => ({
      meta: user.meta,
    })
  );
  let { meta } = useSelector(selectProperties);

  const handleChange = async (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setAssetName(e.target.value);
    try {
      const response: AxiosResponse<RecordsData> = await assetByPageNumber(
        props.referenceType,1,
        e.target.value
      );
      const responseData: any = response;
      setAssetsOption(
        responseData.list?.map((data: any) => ({
          label: data.title,
          value: data[props.referenceField],
        }))
      );
      // Extract the data from the response and set it in the state
    } catch (error) {
      console.error("Error fetching assets:", error);
    }
  };

  const handleSelectChange = (option: Option) => {
    setAssetName(option.label);
    setFormData({...formData,[name]:option.value});
    setIsDropdownOpen(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    //get the name from the id
    const getAssetNameById = async () => {
      try {
        const response: AxiosResponse<RecordsData> = await getContent(
          formData[name],
          props.referenceType
        );
        const responseData: any = response;
        setAssetName(responseData.title);
      } catch (error) {
        console.error("Error fetching asset name:", error);
      }
    };
    if (formData[name] !== "" && formData[name] && assetName === "") {
      getAssetNameById();
    }
  }, [formData, name, props.referenceType]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className={`flex flex-col gap-1 ${props.cols?`lg:col-span-${props.cols}`:'lg:col-span-6'}`}>
      <div className='flex gap-1 items-center'>
      <label className='text-sm'>{props.name}</label>
      {props.longDesc && 
      <>
        <button className='text-mb-blue' onClick={()=>setShowHelper(true)}><Info size={15} /></button>
        <Popup
          open={showHelper}
          onClose={()=>setShowHelper(false)} 
          modal
          lockScroll
          closeOnDocumentClick
          contentStyle={{top:0,right:0,height:'100vh',position:'fixed'}}
          overlayStyle={{background:'rgba(0,0,0,0.6)',zIndex:999999}}
        >
        <div className='h-screen bg-white w-[20rem] relative'>
          <button onClick={()=>setShowHelper(false)} className='absolute right-4 top-4'><X size={30} /></button>
          <div className='text-xl text-mb-blue border-b border-b-mb-blue font-bold p-4'>{props.longDesc.title}</div>
          <div className='text-sm text-gray-900 p-4 flex flex-col gap-4' dangerouslySetInnerHTML={{__html:props.longDesc.body}}></div>
        </div>  
        </Popup>
      </>
    } 
    </div>
    <span className='text-xs'>{props.shortDesc}</span>
      <div className="relative" ref={dropdownRef}>
        <input
          type="text"
          disabled={i18n !== meta.lang}
          value={assetName || ""}
          onChange={handleChange}
          onFocus={() => setIsDropdownOpen(true)}
          name={name}
          className="px-1 py-2 h-9 border !border-mb-blue rounded-md w-full disabled:bg-gray-100 disabled:cursor-not-allowed"
          placeholder={`Search ${props.referenceType}`}
        />
        {isDropdownOpen && (
          <div className="absolute top-full left-0 w-full flex flex-col gap-2 p-1 bg-white border border-slate-200 dark:bg-zink-700 dark:border-zink-500 z-10">
            {assetsOptions?.length === 0 && assetName !== "" && (
              <div className="w-full flex justify-center">
                <p className="p-1 truncate hover:bg-slate-200 dark:hover:bg-zink-600 cursor-pointer">
                  No Result Found
                </p>
              </div>
            )}
            {assetsOptions?.map((assetOption: Option) => (
              <p
                key={assetOption.value}
                className="p-1 truncate hover:bg-slate-200 dark:hover:bg-zink-600 cursor-pointer"
                onClick={() => {
                  handleSelectChange(assetOption);
                }}
              >
                {assetOption.label}
              </p>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default AssetSelector;
