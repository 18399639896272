import { Image, MonitorPlay } from "lucide-react";
import React from "react";
import { Link } from "react-router-dom";
interface Component {
    title: string;
    image: string;
    id:string;
    type:string;
}

const MbTitle: React.FC<Component> = ({ title,image,id,type }) => {
  return(
    <Link className="transition-all flex items-center gap-2 duration-150 ease-linear text-mb-blue hover:text-mb-blue/60" to={`/assets/${type}/${id}`}>
        {
        image !== ""?
        <img src={image} width={30} height={30} className="rounded-full object-cover border-slate-300 border w-[30px] h-[30px]" />:
        <span className="p-1 rounded-full border border-slate-300"><Image size={30} className="text-slate-300" /> </span>
        }
        {title}
    </Link>
  ) 
};

export default MbTitle;