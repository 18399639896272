import BreadCrumb from "Common/BreadCrumb";
import MbLoader from "Common/Components/Icons/MbLoader";
import { createRuleByName, getRules } from "helpers/mbentcms-api_helper";
import {
  BadgeCheck,
  BadgeX,
  Edit3Icon,
  Plus,
  Trash2Icon,
  X,
} from "lucide-react";
import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Popup from "reactjs-popup";

const BusinessRules = () => {
  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState("");
  const [allRulesData, setAllRulesData] = useState([]);
  const [openAddPopup, setOpenAddPopup] = useState(false);
  const [loading, setLoading] = useState(true);
  const [createTitle, setCreateTitle] = useState("");
  const [createError, setCreateError] = useState("");
  const createAsset = useCallback(async () => {
    setLoading(true);
    if (createTitle !== "") {
      try {
        const createRule: any = await createRuleByName(createTitle);
        console.log("createRule == ", createRule);
        navigate("/business-rules/" + createRule.id);
      } catch (e) {
        console.error("Error creating rule:", e);
        setCreateError("Failed to create rule");
      }
    } else {
      setCreateError("Please enter title");
    }
  }, [createTitle]);

  useEffect(() => {
    const getRulesData = async () => {
      setLoading(true);
      try {
        const response: any = await getRules();
        console.log("response == ", response);
        setAllRulesData(response?.list || []);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.error("Error fetching rules data:", err);
      }
    };
    getRulesData();
  }, []);

  return (
    <React.Fragment>
      <div className="w-full min-h-screen flex flex-col text-xs">
        <BreadCrumb title="Business Rules" pageTitle="Entitlement" />
        <div className="card">
          <div className="card-body">
            <div className="search-filters card p-4 flex items-center justify-between gap-4">
              <input
                type="text"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                name="search"
                placeholder="Search or Filter anything..."
                className="h-8 w-1/2 px-4 border border-gray-300 rounded-md grow-0"
              />
              <button
                onClick={() => setOpenAddPopup(true)}
                className="bg-mb-blue rounded-md text-white hover:bg-mb-blue/30 hover:text-mb-blue px-4 py-2 flex items-center gap-1"
              >
                Add New <Plus size={10} />{" "}
              </button>
              <Popup
                modal
                open={openAddPopup}
                onClose={() => setOpenAddPopup(false)}
                overlayStyle={{
                  background: "rgba(0,0,0,0.7)",
                  zIndex: 9999999999999,
                }}
                lockScroll
                closeOnDocumentClick
              >
                <div className="bg-white flex flex-col rounded-md md:w-[30rem]">
                  <div className="flex py-2 px-4 border-b border-b-mb-blue/50 justify-between items-center">
                    <h6>Add New</h6>
                    <button onClick={() => setOpenAddPopup(false)}>
                      <X size={15} />
                    </button>
                  </div>
                  <div className="flex flex-col gap-2 px-4 py-8">
                    {createError && (
                      <p className="text-xs text-mb-red">{createError}</p>
                    )}
                    <label htmlFor="addTitle">Title</label>
                    <input
                      value={createTitle}
                      onChange={(e) => setCreateTitle(e.target.value)}
                      type="text"
                      className="px-1 py-2 h-10 border border-mb-blue rounded-md"
                    />
                  </div>
                  <div className="flex py-2 px-4 border-t border-t-mb-blue/50 justify-end gap-4 items-center">
                    <button
                      className="bg-mb-red text-white rounded-md hover:bg-mb-red/30 hover:text-mb-red px-4 py-2 flex items-center gap-1"
                      onClick={() => setOpenAddPopup(false)}
                    >
                      Cancel
                    </button>
                    <button
                      className="bg-mb-blue text-white rounded-md hover:bg-mb-blue/30 hover:text-mb-blue px-4 py-2 flex items-center gap-1"
                      onClick={createAsset}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </Popup>
            </div>
            {!loading && (
              <div className="overflow-x-auto border border-slate-200 rounded-md">
                <table className="w-full">
                  <thead className="ltr:text-left rtl:text-right ">
                    <tr>
                      <th className="px-3.5 min-w-[200px] py-2.5 font-semibold border-b border-slate-200 dark:border-zink-500">
                        Title
                      </th>
                      <th className="px-3.5 min-w-[200px] py-2.5 font-semibold border-b border-slate-200 dark:border-zink-500">
                        Priority
                      </th>
                      <th className="px-3.5 min-w-[200px] py-2.5 font-semibold border-b border-slate-200 dark:border-zink-500">
                        Status
                      </th>
                      <th className="px-3.5 min-w-[200px] py-2.5 font-semibold border-b border-slate-200 dark:border-zink-500">
                        Access
                      </th>
                      <th className="px-3.5 min-w-[200px] py-2.5 font-semibold border-b border-slate-200 dark:border-zink-500">
                        ID
                      </th>
                      <th className="px-3.5 min-w-[200px] py-2.5 font-semibold border-b border-slate-200 dark:border-zink-500">
                        Model
                      </th>
                      <th className="px-3.5 sticky right-0 min-w-[100px] py-2.5 font-semibold border-b border-slate-200 dark:border-zink-500 text-right bg-white">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {allRulesData.map((asset: any, i: number) => {
                      return (
                        <tr
                          key={asset.id}
                          className="even:bg-slate-50 hover:bg-slate-50 even:hover:bg-slate-100 dark:even:bg-zink-600/50 dark:hover:bg-zink-600 dark:even:hover:bg-zink-600"
                        >
                          <td className="px-3.5 py-2.5 min-w-[300px] border-y border-slate-200 dark:border-zink-500">
                            {asset["title"]}
                          </td>
                          <td className="px-3.5 py-2.5 border-y border-slate-200 dark:border-zink-500">
                            {asset["priority"]}
                          </td>
                          <td className="px-3.5 py-2.5 border-y border-slate-200 dark:border-zink-500">
                            <span
                              className={`${
                                asset["status"] === "draft"
                                  ? "bg-gray-200"
                                  : "bg-mb-green"
                              } p-1`}
                            >
                              {asset["status"]?.toUpperCase()}
                            </span>
                          </td>
                          <td className="px-3.5 py-2.5 border-y border-slate-200 dark:border-zink-500">
                            {asset["access"]}
                          </td>
                          <td className="px-3.5 py-2.5 border-y border-slate-200 dark:border-zink-500">
                            {asset["id"]}
                          </td>
                          <td className="px-3.5 py-2.5 border-y border-slate-200 dark:border-zink-500">
                            {asset["model"]}
                          </td>
                          <td className="px-3.5 py-2.5 sticky right-0 border z-50 bg-white border-slate-200 dark:border-zink-500">
                            <div className="w-full flex gap-2 justify-end">
                              <div>
                                <Link
                                  to={`/business-rules/${asset.id}`}
                                  className="w-auto py-0.5 font-bold text-mb-blue"
                                >
                                  <Edit3Icon
                                    className="inline-block mr-2"
                                    size={15}
                                  />
                                </Link>
                              </div>
                              <div>
                                {asset.status?.toUpperCase() === "DRAFT" ? (
                                  <>
                                    <button
                                      onClick={() => {}}
                                      className="w-auto py-0.5 font-bold text-mb-green"
                                    >
                                      <BadgeCheck
                                        className="inline-block mr-2"
                                        size={15}
                                      />
                                    </button>
                                  </>
                                ) : (
                                  <button className="w-auto py-0.5 font-bold text-mb-blue">
                                    <BadgeX
                                      className="inline-block mr-2"
                                      size={15}
                                      onClick={() => {}}
                                    />
                                  </button>
                                )}
                              </div>
                              <div>
                                <button className="w-auto py-0.5 font-bold text-mb-red">
                                  <Trash2Icon
                                    className="inline-block mr-2"
                                    size={15}
                                  />
                                </button>
                              </div>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
        {loading && <MbLoader />}
      </div>
    </React.Fragment>
  );
};

export default BusinessRules;
