import { AxiosResponse } from "axios";
import { capitalizeFirstLetter } from "coustumFuntions/capitalFirstLetter";
import Select, { StylesConfig } from "react-select";
import {
  getAssetInfo,
  getContent,
  searchAssetByType,
} from "helpers/mb-api_helper";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import DatePicker from "./DatePicker";
import countries from "lib/countries";
import RightDrawer from "Layout/RightDrawer";

interface Contributor {
  selectedLocations: string[];
  excludedLocations: string[];
  startDate: string;
  endDate: string;
}
interface RecordsData {
  [key: string]: any; // Allows any key-value pairs in the user object
}
interface Option {
  readonly label: string;
  readonly value: string;
  readonly isDisabled?: boolean;
}

interface LicensingValue {
  country: string[];
  endDate: string;
  startDate: string;
}

interface Component {
  field: string;
  formValues: { [key: string]: any };
  // setFormValues: React.Dispatch<React.SetStateAction<{ [key: string]: any }>>;
  setValueToForm: (fieldName: string, value: any) => void;
  setMultiLingualValue: (field: string, value: any, activeLang: string) => void;
  element: {
    shortDesc?: string;
    longDesc?: {
      title: string;
      body: string;
    };
    name: string;
    cols: number;
    items: {
      properties: {
        [key: string]: any;
      };
    };
  };
}

const GeoContributorsBox: React.FC<Component> = ({
  field,
  formValues,
  setValueToForm,
  element,
}) => {
  const selectUserMeta = (state: any) => state.User.meta;
  const meta = useSelector(selectUserMeta);

  const newContributor = {
    selectedLocations: [],
    excludedLocations: [],
    startDate: "",
    endDate: "",
  };
  const [contributorsData, setContributorsData] = useState<Contributor[]>(
    formValues[field] || [newContributor]
  );
  const [assetType, setAssetType] = useState<string>("");
  const [assetsOptions, setAssetsOption] = useState<any>([]); // State to store assets options
  const [asset, setAsset] = useState<any>({ value: "", label: "" }); // State to store assets data
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const assetTypeOptions = meta.assetTypes.map((data: string) => ({
    value: data,
    label: capitalizeFirstLetter(data),
  }));

  const countriesOptions = Object.keys(countries).map((key) => ({
    value: key,
    label: countries[key as keyof typeof countries],
  }));

  const colourStyles: StylesConfig<any, true> = {
    control: (styles) => ({ ...styles, backgroundColor: "white" }),
    multiValue: (styles) => {
      return {
        ...styles,
        backgroundColor: "#457abd",
      };
    },
    multiValueLabel: (styles) => ({
      ...styles,
      color: "white",
    }),
  };

  const handleAssetTypeChange = (selectedOption: Option | null) => {
    setAssetType(selectedOption ? selectedOption.value : "");
  };

  const handleAssetChange = (selectedOption: any | null) => {
    setAsset(selectedOption);
    const getContentInfo = async () => {
      try {
        const contentRes: any = await getContent(
          selectedOption.value,
          assetType
        );
        const assetArray = contentRes[field] || [];
        setContributorsData((preVal) => [...preVal, ...assetArray]);
        // setFormValues((prevValues) => ({
        //   ...prevValues,
        //   [field]: [...contributorsData, ...assetArray],
        // }));
        setIsDropdownOpen(false);
        // Extract the data from the response and set it in the state
      } catch (error) {
        console.error("Error fetching assets:", error);
      }
    };
    if (selectedOption && assetType !== "") {
      getContentInfo(); // Call the async function to fetch assets
    }
  };

  const handleSearchAsset = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setAsset({ value: e.target.value, label: e.target.value });
    try {
      const response: AxiosResponse<RecordsData> = await searchAssetByType(
        assetType,
        e.target.value
      );
      const responseData: any = response;
      setAssetsOption(
        responseData.list?.map((data: any) => ({
          label: data.title,
          value: data.id,
        }))
      );
      // Extract the data from the response and set it in the state
    } catch (error) {
      console.error("Error fetching assets:", error);
    }
  };

  const removeContributor = (index: number) => {
    const updatedContributors = contributorsData.filter((_, i) => i !== index);
    setContributorsData(updatedContributors);
  };

  const addContributor = () => {
    setContributorsData([...contributorsData, newContributor]);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsDropdownOpen(false);
    }
  };

  const handleChange = (
    newValue: RecordsData,
    field: string,
    index: number
  ) => {
    setContributorsData((preVal: Contributor[]) =>
      preVal.map((val, i) =>
        index === i
          ? {
              ...val,
              [field]: newValue
                ? (newValue as Option[]).map((option) => option.value)
                : [],
            }
          : val
      )
    );
  };

  // const handleFieldChange = (index: number, updateFunc: any) => {
  //   setContributorsData((prevData: any) =>
  //     prevData.map((obj: any, i: number) =>
  //       i === index ? updateFunc(obj) : obj
  //     )
  //   );
  // };
  const handleFieldChange = (
    index: number,
    subField: string,
    value: string
  ) => {
    console.log("000000", formValues[field]);
    console.log("11111", field);
    console.log("22222", formValues);

    const updatedContributors = [...formValues[field]];
    updatedContributors[index][subField] = value;

    setValueToForm(field, updatedContributors);
    setContributorsData(updatedContributors);
  };

  useEffect(() => {
    setAsset({ value: "", label: "" });
    const fetchAssets = async () => {
      try {
        const response: AxiosResponse<RecordsData> = await getAssetInfo(
          assetType
        ); // Await the API call to fetch asset data
        const responseData: any = response;
        setAssetsOption(
          responseData.list?.map((data: any) => ({
            label: data.title,
            value: data.id,
          }))
        );
        // Extract the data from the response and set it in the state
      } catch (error) {
        console.error("Error fetching assets:", error);
      }
    };

    if (assetType !== "") {
      fetchAssets(); // Call the async function to fetch assets
    }
  }, [assetType]);

  useEffect(() => {
    const licensingValues: LicensingValue[] = contributorsData?.map(
      (object) => {
        return {
          country:
            object.selectedLocations?.length > 0
              ? object.selectedLocations
              : object.excludedLocations?.length > 0
              ? countriesOptions
                  .filter(
                    (option) => !object.excludedLocations.includes(option.value)
                  )
                  .map((option) => option.value)
              : [],
          endDate: object.endDate,
          startDate: object.startDate,
        };
      }
    );
    setValueToForm("licensing", licensingValues);
    // setFormValues((prevValues) => ({
    //   ...prevValues,
    //   [field]: contributorsData,
    //   licensing: licensingValues,
    // }));
  }, [contributorsData, field]);

  useEffect(() => {
    setValueToForm(field, contributorsData);
  }, [formValues["licensing"]]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className={`lg:col-span-${element.cols || 12}`}>
      <div className="">
        <div className="flex flex-col gap-1 mb-2">
          <div className="flex items-center gap-2">
            <h5>{element.name}</h5>
            {element.longDesc && (
              <RightDrawer
                title={element.longDesc.title}
                body={element.longDesc.body}
              />
            )}
          </div>
          {element.shortDesc && element.shortDesc !== "" && (
            <span className="text-sm">{element.shortDesc}</span>
          )}
        </div>
        <div className="flex flex-col gap-4">
          {contributorsData.map((contributor: Contributor, index: number) => (
            <div
              key={index}
              className="relative grid grid-cols-1 gap-5 lg:grid-cols-12 mt-3 border rounded-lg shadow-lg p-4"
            >
              <div className={`absolute top-2 right-2`}>
                {contributorsData?.length > 1 && (
                  <button
                    className="text-mb-red/90 hover:text-mb-red"
                    onClick={() => removeContributor(index)}
                  >
                    {" "}
                    <svg
                      className="w-6 h-6 "
                      fill="currentColor"
                      focusable="false"
                      aria-hidden="true"
                      viewBox="0 0 24 24"
                      data-testid="CancelIcon"
                    >
                      <path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2m5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12z"></path>
                    </svg>
                  </button>
                )}
              </div>
              <div className="lg:col-span-6">
                <label className="inline-block mb-2 text-base font-medium">
                  Selected Location
                </label>
                <Select
                  styles={colourStyles}
                  className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-mb-blue disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  id="choices-multiple-remove-button"
                  name="choices-multiple-remove-button"
                  value={contributor.selectedLocations?.map((value: string) =>
                    countriesOptions.find((data) => data.value === value)
                  )}
                  isClearable
                  isDisabled={contributor.excludedLocations?.length > 0}
                  isMulti
                  isSearchable
                  options={countriesOptions}
                  onChange={(newValue) =>
                    handleChange(newValue, "selectedLocations", index)
                  }
                />
              </div>

              <div className="lg:col-span-6">
                <label className="inline-block mb-2 text-base font-medium">
                  Excluded Location
                </label>
                <Select
                  className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-mb-blue disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  id="choices-multiple-remove-button"
                  name="choices-multiple-remove-button"
                  value={contributor.excludedLocations?.map((value: string) =>
                    countriesOptions.find((data) => data.value === value)
                  )}
                  isClearable
                  isDisabled={contributor.selectedLocations?.length > 0}
                  isMulti
                  isSearchable
                  options={countriesOptions}
                  onChange={(newValue) =>
                    handleChange(newValue, "excludedLocations", index)
                  }
                />
              </div>
              <DatePicker
                element={{ name: "Start Date", cols: 6 }}
                field={"startDate"}
                formValues={contributor}
                setValueToForm={(field: string, value: any) =>
                  handleFieldChange(index, field, value)
                }
              />
              <DatePicker
                element={{ name: "End Date", cols: 6 }}
                field={"endDate"}
                formValues={contributor}
                setValueToForm={(field: string, value: any) =>
                  handleFieldChange(index, field, value)
                }
              />
            </div>
          ))}
          <div className="flex gap-4 items-center">
            <button
              type="button"
              className="btn bg-mb-blue text-white"
              onClick={addContributor}
            >
              Add New
            </button>
            <p className="text-xs">Or Copy From</p>
            <Select
              className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-mb-blue disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
              data-choices
              name="choices-single-default"
              options={assetTypeOptions}
              onChange={handleAssetTypeChange}
              value={assetTypeOptions.find(
                (option: Option) => option.value === assetType
              )}
            />{" "}
            {assetType && (
              <div className="relative" ref={dropdownRef}>
                <input
                  type="text"
                  placeholder={`Search ${assetType}...`}
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-mb-blue disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  onChange={handleSearchAsset}
                  onFocus={() => setIsDropdownOpen(true)}
                  value={asset.label}
                />
                {isDropdownOpen && (
                  <div className="absolute top-full left-0 w-full flex flex-col gap-2 p-1 bg-white border border-slate-200 dark:bg-zink-700 dark:border-zink-500 z-10">
                    {assetsOptions.length === 0 && (
                      <p className="p-1">No result found</p>
                    )}
                    {assetsOptions?.map((assetOption: Option) => (
                      <p
                        key={assetOption.value}
                        className="p-1 truncate hover:bg-slate-200 dark:hover:bg-zink-600 cursor-pointer"
                        onClick={() => {
                          handleAssetChange(assetOption);
                        }}
                      >
                        {assetOption.label}
                      </p>
                    ))}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeoContributorsBox;
