import { createSelector } from "@reduxjs/toolkit";
import BreadCrumb from "Common/BreadCrumb";
import Alert from "Common/Components/Alert";
import GetComponent from "components/commonComponents/getComponent";
import { capitalizeFirstLetter } from "coustumFuntions/capitalFirstLetter";
import {
  getAssetInfo,
  getContent,
  getMetaTemplateData,
  getMetaUiData,
  updateAssetInformation,
} from "helpers/mb-api_helper";
import SnackBar from "Layout/SnackBar";
import isoLangs from "lib/languages";
import {
  BadgeCheck,
  Check,
  ChevronLeft,
  ChevronRight,
  Globe2,
  Languages,
  LucideProps,
  SaveAll,
  Settings,
  Settings2,
  Shield,
  ShieldCheck,
  Trash2,
  Undo,
} from "lucide-react";
import dynamicIconImports from "lucide-react/dynamicIconImports";
import React, { Suspense, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Popup from "reactjs-popup";

interface AnyObject {
  [key: string]: any; // Allows any key-value pairs in the user object
}
///Show lucide Icon  Dynamically
const fallback = <div style={{ background: "#ddd", width: 24, height: 24 }} />;
interface IconProps extends Omit<LucideProps, "ref"> {
  name: keyof typeof dynamicIconImports;
}
const Icon = ({ name, ...props }: IconProps) => {
  const LucideIcon = React.lazy(dynamicIconImports[name]);
  return (
    <Suspense fallback={fallback}>
      <LucideIcon {...props} />
    </Suspense>
  );
};

///End show lucid icon dynamically
const AssetsEditPage = () => {
  let selectProperties = createSelector(
    (state: any) => state.User,
    (user) => ({
      meta: user.meta,
    })
  );
  let { meta } = useSelector(selectProperties);
  const { type, id } = useParams<{ type: string; id: string }>(); // Destructure `type` from useParams
  const [assetTemplate, setAssetTemplate] = useState<AnyObject>({});
  const [i18n, setI18n] = useState(meta.lang);
  const [assetUI, setAssetUI] = useState<AnyObject>({});
  const [assetData, setAssetData] = useState<AnyObject>({});
  const [formData, setFormData] = useState<AnyObject>({});
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");

  const [selectedSection, setSelectedSection] = useState(0);
  useEffect(() => {
    const getData = async () => {
      let assetDetails: AnyObject = await getContent(id, type);
      let metaUIRes: any = await getMetaUiData(type);
      let metaTemplateRes: any = await getMetaTemplateData(type);
      setFormData(assetDetails);
      setAssetData(assetDetails);
      setAssetUI(metaUIRes);
      setAssetTemplate(metaTemplateRes);
    };
    getData();
  }, [type, id]);
  const changeTab = useCallback(async () => {
    if (JSON.stringify(assetData) !== JSON.stringify(formData)) {
      try {
        let updateRes = await updateAssetInformation(
          assetData.id,
          type,
          formData
        );
        setAssetData({ ...updateRes });
        setFormData({ ...updateRes });
        setSuccess("Asset saved successfully!");
      } catch (e) {
        console.log(e);
        setError("There was a problem saving asset.");
      }
    }
  }, [assetData, formData, type]);
  const saveDraft = useCallback(async () => {
    try {
      let updateRes = await updateAssetInformation(
        assetData.id,
        type,
        formData
      );
      setAssetData({ ...updateRes });
      setFormData({ ...updateRes });
      setSuccess("Asset saved successfully!");
    } catch (e) {
      console.log(e);
      setError("There was a problem saving asset.");
    }
  }, [assetData, formData, type]);
  const publishAsset = useCallback(async () => {
    try {
      const currentTimeStamp = Date.now(); // returns an integer timestamp
      const assetDataToUpdate = {
        ...formData,
        v_status: "PUBLISHED",
        v_published: true,
        ...(assetData.v_published ? {} : { v_pt: currentTimeStamp }), // conditionally add v_pt
      };

      let publishRes = await updateAssetInformation(
        assetData.id,
        type,
        assetDataToUpdate
      );

      console.log("this is a data of i need", formData);
      if (!formData?.v_published) {
        if (formData?.v_type === "seasons" && formData?.showId) {
          setTimeout(async () => {
            try {
              const filterString = `seasons?filters=showId:${formData?.showId}`;
              const response: any = await getAssetInfo(filterString);
              await updateAssetInformation(formData?.showId, "series", {
                seasonCount: response?.total,
              });
            } catch (error) {
              console.error("Error updating asset information:", error);
            }
          }, 1000);
        }

        if (formData?.v_type === "episodes") {
          setTimeout(async () => {
            try {
              if (formData?.showId) {
                const filterString = `episodes?filters=showId:${formData?.showId}`;
                const response: any = await getAssetInfo(filterString);
                await updateAssetInformation(formData?.showId, "series", {
                  episodeCount: response?.total,
                });
              }

              if (formData?.seasonId) {
                const filterString = `episodes?filters=seasonId:${formData?.seasonId}`;
                const response: any = await getAssetInfo(filterString);
                await updateAssetInformation(formData?.seasonId, "seasons", {
                  episodeCount: response?.total,
                });
              }
            } catch (error) {
              console.error("Error updating asset information:", error);
            }
          }, 1000);
        }
      }

      setSuccess("Asset published successfully!");
    } catch (e) {
      console.log(e);
      setError("There was a problem publishing asset.");
    }
  }, [assetData, formData, type]);
  useEffect(() => {
    console.log("FormData Main Form", formData);
  }, [formData]);
  return (
    <React.Fragment>
      <div className="w-full min-h-screen flex flex-col">
        <BreadCrumb
          backRoute={`/assets/${type}`}
          title={`Edit ${capitalizeFirstLetter(type)
            .replace(/([A-Z])/g, " $1")
            .trim()}`}
          pageTitle="Asset Management"
        />
        {assetTemplate.id && assetUI.id && (
          <div className="card">
            <div className="card-body">
              <div className="flex justify-between items-center card px-4 py-2">
                <div className="flex flex-col gap-1">
                  <h2 className="text-lg font-bold">
                    Editing : {assetData.title} -{" "}
                    <span className="text-xs">(In {isoLangs[i18n].name})</span>
                  </h2>
                  <span
                    className={`text-xs font-semibold ${
                      assetData.v_published ? "text-mb-red" : "text-mb-blue"
                    }`}
                  >
                    You are editing{" "}
                    {assetData.v_published ? "published" : "draft"} version.
                  </span>
                </div>
                <div className="flex items-center justify-end gap-4">
                  <Popup
                    arrow
                    on={"click"}
                    position={"bottom right"}
                    closeOnDocumentClick
                    trigger={
                      <button className="text-mb-blue">
                        <Globe2 size={15} />
                      </button>
                    }
                  >
                    <div className="w-[200px] flex flex-col gap-0 border border-gray-300 bg-white mt-2 rounded-md overflow-hidden">
                      <div className="bg-mb-blue text-white py-1 px-2 font-bold">
                        Change Language
                      </div>
                      {meta.languages.map((l: string, i: number) => (
                        <button
                          key={i}
                          onClick={() => {
                            setI18n(l);
                          }}
                          className="px-4 py-2 bg-slate-100 border-b border-b-slate-300 hover:bg-mb-blue hover:text-white text-left flex items-center gap-1"
                        >
                          {i18n === l ? <Check size={10} /> : <></>}
                          {isoLangs[l].name}
                        </button>
                      ))}
                    </div>
                  </Popup>
                  <Popup
                    arrow
                    on={"click"}
                    position={"bottom right"}
                    trigger={
                      <button className="text-mb-blue">
                        <Settings size={15} />
                      </button>
                    }
                  >
                    <div className="w-[200px] flex flex-col gap-0 border border-gray-300 bg-white mt-2 rounded-md overflow-hidden">
                      <div className="bg-mb-blue text-white py-1 px-2 font-bold">
                        Manage - {assetData.title}
                      </div>
                      <button className="px-4 py-2 bg-slate-100 border-b border-b-slate-300 hover:bg-mb-blue hover:text-white text-left flex items-center gap-1">
                        <Check size={15} />
                        Validate Fields
                      </button>
                      <button className="px-4 py-2 bg-slate-100 border-b border-b-slate-300 hover:bg-mb-blue hover:text-white text-left flex items-center gap-1">
                        <Trash2 size={15} />
                        Delete
                      </button>
                      <button
                        onClick={() =>
                          i18n === meta.lang
                            ? setFormData({ ...assetData })
                            : setI18n(meta.lang)
                        }
                        className="px-4 py-2 bg-slate-100 border-b border-b-slate-300 hover:bg-mb-blue hover:text-white text-left flex items-center gap-1"
                      >
                        <Undo size={15} />
                        Undo All Changes
                      </button>
                    </div>
                  </Popup>
                </div>
              </div>
              <div className="errors success">
                {success && (
                  <>
                    <SnackBar
                      message={success}
                      onClose={() => setSuccess("")}
                    />
                    <Alert className="relative px-4 py-3 text-sm text-green-500 border border-transparent rounded-md bg-green-50 dark:bg-green-400/20">
                      <Alert.Close className="absolute top-0 bottom-0 right-0 p-3 transition text-custom-200 hover:text-green-500 dark:text-custom-400/50 dark:hover:text-custom-500" />
                      <Alert.Bold>Success! </Alert.Bold>
                      {success}
                    </Alert>
                  </>
                )}
                {error && (
                  <Alert className="relative px-4 py-3 text-sm text-orange-500 border border-transparent rounded-md bg-orange-50 dark:bg-orange-400/20">
                    <Alert.Close className="absolute top-0 bottom-0 right-0 p-3 transition text-custom-200 hover:text-orange-500 dark:text-custom-400/50 dark:hover:text-custom-500" />
                    <Alert.Bold>Error! </Alert.Bold>
                    {error}
                  </Alert>
                )}
              </div>
              <div className="tabs flex flex-nowrap items-center justify-start gap-0 max-w-full overflow-x-auto scrollbar-hide">
                {assetUI.section.map((section: AnyObject, i: number) => {
                  return (
                    <button
                      onClick={() => {
                        changeTab();
                        setSelectedSection(i);
                      }}
                      key={i}
                      className={`${
                        selectedSection === i
                          ? "bg-white border-b-0 text-mb-blue"
                          : "bg-mb-blue/10 hover:bg-mb-blue/20"
                      } ${i == 0 ? "rounded-tl-md" : ""} ${
                        i === assetUI.section.length - 1 ? "rounded-tr-md" : ""
                      } border border-slate-300 px-4 py-2 flex items-center justify-center gap-2 text-nowrap min-w-[150px] text-center transition-all duration-500`}
                    >
                      <Icon name={section.icon} size={15} className="" />
                      {section.name}
                    </button>
                  );
                })}
              </div>
              <div className="w-full grid lg:grid-cols-12 gap-6 border border-slate-300 p-4 -mt-[2px] min-w-full">
                {assetUI.section[selectedSection].fields.map(
                  (field: string, i: number) => {
                    if (
                      assetTemplate.properties[field] &&
                      assetTemplate.properties[field].showInUi === true
                    ) {
                      return (
                        <GetComponent
                          key={`${assetUI.section[selectedSection].name}-${i}`}
                          i18n={i18n}
                          name={field}
                          formData={formData}
                          setFormData={setFormData}
                          props={assetTemplate.properties[field]}
                        />
                      );
                    }
                  }
                )}
              </div>
              <div className="mt-4 overflow-x-auto scrollbar-hide">
                <div className="flex gap-4 items-center justify-start lg:justify-end font-bold">
                  <button
                    disabled={selectedSection === 0}
                    onClick={() => {
                      setSelectedSection(selectedSection - 1);
                    }}
                    className="bg-mb-blue rounded-md text-white hover:bg-mb-blue/30 hover:text-mb-blue px-4 py-2 flex items-center gap-1 text-nowrap disabled:bg-gray-200 disabled:hover:bg-gray-200 disabled:hover:text-white"
                  >
                    <ChevronLeft size={15} /> Prev
                  </button>
                  <button
                    disabled={assetData.v_published}
                    onClick={saveDraft}
                    className="bg-mb-blue rounded-md text-white hover:bg-mb-blue/30 hover:text-mb-blue px-4 py-2 flex items-center gap-1 text-nowrap disabled:bg-gray-200 disabled:hover:bg-gray-200 disabled:hover:text-white"
                  >
                    <SaveAll size={15} />
                    Save Draft
                  </button>
                  <button
                    onClick={publishAsset}
                    className="bg-mb-green rounded-md text-white hover:bg-mb-green/30 hover:text-mb-green px-4 py-2 flex items-center gap-1 text-nowrap disabled:bg-gray-200 disabled:hover:bg-gray-200 disabled:hover:text-white"
                  >
                    <ShieldCheck size={15} />
                    {assetData.v_published ? "Save" : "Publish"}
                  </button>
                  <button
                    disabled={selectedSection === assetUI.section.length - 1}
                    onClick={() => {
                      setSelectedSection(selectedSection + 1);
                    }}
                    className="bg-mb-blue rounded-md text-white hover:bg-mb-blue/30 hover:text-mb-blue px-4 py-2 flex items-center gap-1 text-nowrap disabled:bg-gray-200 disabled:hover:bg-gray-200 disabled:hover:text-white"
                  >
                    Next <ChevronRight size={15} />
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default AssetsEditPage;
