
import { createSelector } from '@reduxjs/toolkit';
import { slugify } from 'helpers/utility';
import isoLangs from 'lib/languages';
import { Info, X } from 'lucide-react';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Popup from 'reactjs-popup';

interface InputProps {
  name:string  
  props:any
  formData?: any;
  setFormData: React.Dispatch<React.SetStateAction<any>>
  i18n:string
}

const TextBox: React.FC<InputProps> = ({name, props, formData={}, setFormData,i18n }) =>{
const [showHelper,setShowHelper] = useState(false)
const [isDisabled,setIsDisabled] = useState(false)

const [value,setValue] = useState(formData[name] || "")

let selectProperties = createSelector(
  (state: any) => state.User,
  (user) => ({
    meta: user.meta,
  })
);
let { meta } = useSelector(selectProperties);
useEffect(()=>{
  

  if(!props.isMultiLingual && i18n !== meta.lang){
    console.log(name,"Conditions 1",i18n)
    setValue(props.autoGenerate && !formData[name]?slugify(formData[props.autoGenerateBy]):formData[name]?formData[name]:"")
    setIsDisabled(true)
  }
  else if(props.isMultiLingual && i18n !== meta.lang){
    if(!formData.i18n || !formData.i18n[i18n]){
      
      setFormData({...formData,"i18n":{[i18n]:{[name]:""}}})
      setValue("")
    }
    else if(!formData.i18n[i18n]){
      
      setFormData({...formData.i18n,[i18n]:{[name]:""}})
      setValue("")
    }else{
      
      setValue(formData.i18n[i18n][name] || "")
    }
  }
  else{
    setIsDisabled(false)
    setValue(props.autoGenerate && !formData[name]?slugify(formData[props.autoGenerateBy]):formData[name]?formData[name]:"")
  }  
},[i18n])

const updateFormData = useCallback(()=>{
  if(props.isMultiLingual && i18n !== meta.lang){
    let newFormData = formData
    newFormData.i18n[i18n][name] = value
    setFormData({...newFormData})
  }else{
    setFormData({...formData,[name]:value})
  }
},[value])

return (
  <div className={`flex flex-col gap-1 ${props.cols?`lg:col-span-${props.cols}`:'lg:col-span-6'} max-w-full`}>
    <div className='flex gap-1 items-center'>
      <label className='text-sm'>{props.name}</label>
      {props.longDesc && 
      <>
        <button className='text-mb-blue' onClick={()=>setShowHelper(true)}><Info size={15} /></button>
        <Popup
          open={showHelper}
          onClose={()=>setShowHelper(false)} 
          modal
          lockScroll
          closeOnDocumentClick
          contentStyle={{top:0,right:0,height:'100vh',position:'fixed'}}
          overlayStyle={{background:'rgba(0,0,0,0.6)',zIndex:999999}}
        >
        <div className='h-screen bg-white w-[20rem] relative'>
          <button onClick={()=>setShowHelper(false)} className='absolute right-4 top-4'><X size={30} /></button>
          <div className='text-xl text-mb-blue border-b border-b-mb-blue font-bold p-4'>{props.longDesc.title}</div>
          <div className='text-sm text-gray-900 p-4 flex flex-col gap-4' dangerouslySetInnerHTML={{__html:props.longDesc.body}}></div>
        </div>  
        </Popup>
      </>
    } 
    </div>
    <span className='text-xs'>{props.shortDesc}</span>
    <input name={name} placeholder={`Enter ${props.name}`} onBlur={updateFormData} disabled={isDisabled} readOnly={props.editable?!props.editable:false} className='px-1 py-2 h-9 border !border-mb-blue rounded-md w-full disabled:bg-gray-100 disabled:cursor-not-allowed' type={props.type==='number'?'number':'text'} value={value} onChange={(e)=>setValue(e.target.value)} />
    {i18n !== meta.lang && props.isMultiLingual && <div className='text-xs'><span className='font-bold'>{isoLangs[meta.lang].name} Value: </span>{formData[name]}</div>}
    
  </div>
)};

export default React.memo(TextBox);