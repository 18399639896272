import RightDrawer from "Layout/RightDrawer";
import React, { useState } from "react";

interface Component {
  field: string;
  formValues: { [key: string]: any };
  // setFormValues: React.Dispatch<React.SetStateAction<{ [key: string]: any }>>;
  setValueToForm: (fieldName: string, value: any) => void;
  element: {
    shortDesc?: string;
    longDesc?: {
      title: string;
      body: string;
    };
    name: string;
    cols: number;
    isMultiLingual: boolean;
    autoGenerate?: boolean;
    autoGenerateBy: string;
  };
}
const TagBox: React.FC<Component> = ({
  element,
  field,
  formValues,
  setValueToForm,
}) => {
  const [inputValue, setInputValue] = useState<string>("");
  const [chips, setChips] = useState<string[]>(formValues[field] || []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && inputValue.trim()) {
      e.preventDefault();
      if (!chips.includes(inputValue.trim())) {
        setChips([...chips, inputValue.trim()]);
        setValueToForm(field, [...chips, inputValue.trim()]);
        // setFormValues((prevValues) => ({
        //   ...prevValues,
        //   [field]: [...chips, inputValue.trim()],
        // }));
      }
      setInputValue("");
    }
  };

  const handleDeleteChip = (chipToDelete: string) => {
    setChips(chips.filter((chip) => chip !== chipToDelete));
  };

  return (
    <div className={`lg:col-span-${element.cols || 12}`}>
      <div className="flex flex-col gap-1 mb-2">
        <div className="flex items-center gap-2">
          <label className="inline-block text-base font-medium">
            {element.name}
          </label>
          {element.longDesc && (
            <RightDrawer
              title={element.longDesc.title}
              body={element.longDesc.body}
            />
          )}
        </div>
        {element.shortDesc && element.shortDesc !== "" && (
          <span className="text-sm">{element.shortDesc}</span>
        )}
      </div>
      <div className=" flex flex-wrap items-center border border-gray-300 rounded-lg p-2 py-1 ">
        <div className="flex flex-wrap items-center">
          {chips.map((chip, index) => (
            <div
              key={index}
              className="flex items-center bg-mb-blue text-white text-sm px-2 py-1 m-1 rounded-lg"
            >
              {chip}
              <button
                onClick={() => handleDeleteChip(chip)}
                className="ml-1 text-white hover:text-gray-200 focus:outline-none"
              >
                &times;
              </button>
            </div>
          ))}
        </div>
        <input
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          placeholder="Type and press Enter..."
          className="flex-grow p-2 outline-none border-none"
        />
      </div>
    </div>
  );
};

export default TagBox;
