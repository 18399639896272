import { createSelector } from "@reduxjs/toolkit";
import BreadCrumb from "Common/BreadCrumb";
import MbLoader from "Common/Components/Icons/MbLoader";
import {
  createDraftView,
  getDraftViewById,
  getPlatformById,
  getPlatforms,
  getPreSignedUrl,
  getRails,
  getRailsListTags,
  publishView,
  updateDraftView,
  uploadFile,
} from "helpers/mb-api_helper";
import SnackBar from "Layout/SnackBar";
import countries from "lib/countries";
import isoLangs from "lib/languages";
import {
  Check,
  Globe2,
  MoveVertical,
  Plus,
  SaveAll,
  ShieldCheck,
  X,
} from "lucide-react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { ReactSortable } from "react-sortablejs";
import Popup from "reactjs-popup";
import {
  Accordion,
  Button,
  Checkbox,
  DatePicker,
  SelectPicker,
  TagPicker,
  Toggle,
} from "rsuite";
import EditorialEditRailsSection from "./EditorialEditRailsSection";
import { v4 as uuidv4 } from "uuid";

interface AnyObject {
  [key: string]: any; // Allows any key-value pairs in the user object
}

const ListRails: React.FC = () => {
  const { viewId } = useParams();
  const selectProperties = createSelector(
    (state: any) => state.User,
    (user) => ({
      meta: user.meta,
    })
  );
  const { meta } = useSelector(selectProperties);
  const navigate = useNavigate();

  const [sortableData, setSortableData] = useState<any>([]);
  const [i18n, setI18n] = useState(meta.lang);
  const [selectedSection, setSelectedSection] = useState(0);
  const [selectedLang, setSelectedLang] = useState(meta.lang);
  const [file, setFile] = useState("");
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [platformList, setPlatformList] = useState([]);
  const [platformData, setPlatformData] = useState<AnyObject>({});
  const [isLoading, setIsLoading] = useState(true);
  const [openRailID, setOpenRailId] = useState(-1);
  const [viewData, setViewData] = useState<AnyObject>({});
  const [parentViewData, setParentViewData] = useState<AnyObject>({});
  const [saveDraftClicked, setSaveDraftClicked] = useState(false);
  const [published, setPublished] = useState(false);
  const [message, setMessage] = useState("");
  const [openAddPopup, setOpenAddPopup] = useState(false);
  const [allRailsData, setAllRailsData] = useState<any>([]);
  const [tagsData, setTagsData] = useState<any>([]);
  const [cacheTagData, setCacheTagData] = useState<any>([]); //Option to cache value when searching asynchronously
  const [layOut, setLayOut] = useState();
  const [readyToPublish, setReadyToPublish] = useState(false);
  const [createTitle, setCreateTitle] = useState("");
  const [createError, setCreateError] = useState("");

  const handleChangeName = (value: string, key: string) => {
    // if (formErrorMassage !== "") setFormErrorMassage("");
    setViewData(() => ({ ...viewData, [key]: value }));
  };

  const handleSelectTag = (value: any, item: any, event: any) => {
    console.log("33333333", item);

    setCacheTagData([...cacheTagData, item]);
  };
  const handleFeatTags = async (searchKey: string) => {
    try {
      const response: any = await getRailsListTags(searchKey);
      if (response?.list?.length > 0) {
        setTagsData(response?.list);
      }
    } catch (err) {}
  };

  const handlerI18nValueChange = (value: string, key: string) => {
    const data = { ...viewData };

    // Ensure data.i18n and data.i18n[i18n] are defined
    if (!data.i18n) {
      data.i18n = {}; // Initialize if undefined
    }
    if (!data.i18n[i18n]) {
      data.i18n[i18n] = {}; // Initialize nested object if undefined
    }

    data.i18n[i18n][key] = value;
    setViewData(() => ({ ...data }));
  };

  const onUpload = async (e: any) => {
    let file = e.target.files[0];
    let fileName = uuidv4();
    try {
      let signedUrl: any = await getPreSignedUrl(
        `images/${fileName}.${file.type.replace("image/", "")}`
      );
      let uploadFileRes = await uploadFile(signedUrl.url, file);
      setFile(signedUrl.file);
      setViewData({ ...viewData, og_image: signedUrl.file });
    } catch (e) {
      console.log(e);
    }
  };

  const handleSaveDraftRails = async () => {
    try {
      await updateDraftView(viewId, viewData);
      setSuccess("Rails saved successfully.");
      setSaveDraftClicked(true);
    } catch (error: any) {
      // 404
      if (error.response && error.response.status === 404) {
        setError("Please refresh the page  and try again.");
      } else {
        setError("Failed to saved Rails. Please try again later.");
      }
    }
  };

  const handlePublishRails = async () => {
    try {
      await publishView(viewId);
      setSuccess("Rails published successfully.");
      setPublished(true);
      if (viewData?.v_platform) {
        navigate(`/views/${viewData?.v_platform}`);
      } else {
        navigate(`/platform`);
      }
    } catch (error: any) {
      // if (error.response && error.response.status === 412) {
      setError(
        "Rails publishing failed because the rail is not updated. Please check and try again."
      );
      // } else {
      //   setError("Failed to publish Rails. Please try again later.");
      // }
    }
  };

  const handleAdd = (item: any) => {
    if (viewData && viewData.rails && viewData.rails.list) {
      setViewData((prevData) => ({
        ...prevData,
        rails: {
          ...prevData.rails,
          list: [...prevData.rails.list, { ...item, active: true }],
        },
      }));
      setSortableData((prevData: any) => [
        ...prevData,
        { ...item, active: true },
      ]);
      setSaveDraftClicked(false);
      setPublished(false);
      setOpenAddPopup(false);
    } else {
      setViewData(() => ({
        rails: {
          list: [{ ...item, active: true }],
        },
      }));
      setSortableData((prevData: any) => [
        ...prevData,
        { ...item, active: true },
      ]);
      setSaveDraftClicked(false);
      setPublished(false);
      setOpenAddPopup(false);
    }
  };

  // Function to create the apiPath
  const createApiPath = (
    apiPathRef: any,
    railInstanceId: any,
    filterData: any,
    sortData: any,
    sortValues: any
  ) => {
    // Step 1: Replace :collectionId with railInstanceId or remove it if it's empty
    let apiPath = apiPathRef;
    if (railInstanceId) {
      apiPath = apiPathRef.replace(":collectionId", railInstanceId);
    } else {
      // Remove ':collectionId' part if railInstanceId is empty
      apiPath = apiPathRef.replace("/:collectionId", "");
    }

    // Step 2: Build the filter string if filterData is present and valid
    if (Array.isArray(filterData) && filterData.length > 0) {
      const filterStrings = filterData
        .filter(
          (filter) => filter?.filterValues?.length > 0 && filter.filterOperator
        ) // Ensure filterValues and filterOperator are valid
        .map((filter) => {
          const values = filter.filterValues.join(",");
          return `${filter.filterData}${filter.filterOperator}${values}`;
        });

      if (filterStrings.length > 0) {
        apiPath += `?filters=${filterStrings.join(";")}`;
      }
    }

    // Step 3: Add sorting parameters if sortData and sortValues are valid
    if (sortData && sortValues) {
      const sortOrder = sortValues === "ascending" ? "asc" : "desc";
      apiPath += apiPath.includes("?")
        ? `&sort=${sortData}:${sortOrder}`
        : `?sort=${sortData}:${sortOrder}`;
    }

    return apiPath;
  };

  const handleRailInfoChange = async (
    index: number,
    key: string,
    value: any
  ) => {
    console.log("handleRailInfoChange", key);

    // let newViewData = viewData;
    // newViewData.rails.list[index][key] = value;
    // if (
    //   key === "filterData" ||
    //   key === "sortData" ||
    //   key === "sortValues" ||
    //   key === "railInstanceId"
    // ) {
    //   const apiPath = createApiPath(
    //     newViewData?.rails?.list[index]?.apiPathRef,
    //     newViewData?.rails?.list[index]?.railInstanceId,
    //     newViewData?.rails?.list[index]?.filterData,
    //     newViewData?.rails?.list[index]?.sortData,
    //     newViewData?.rails?.list[index]?.sortValues
    //   );
    //   console.log("this is api path", apiPath);
    //   newViewData.rails.list[index].apiPath = apiPath;
    // }

    const newSortableData = sortableData;
    newSortableData[index][key] = value;
    if (
      key === "filterData" ||
      key === "sortData" ||
      key === "sortValues" ||
      key === "railInstanceId"
    ) {
      const apiPath = createApiPath(
        newSortableData[index]?.apiPathRef,
        newSortableData[index]?.railInstanceId,
        newSortableData[index]?.filterData,
        newSortableData[index]?.sortData,
        newSortableData[index]?.sortValues
      );
      console.log("this is api path", apiPath);
      newSortableData[index].apiPath = apiPath;
    }

    setViewData({
      ...viewData,
      rails: {
        ...viewData.rails,
        list: newSortableData?.filter((rail: any) => !rail?.isParentRail),
      },
    });
    setSaveDraftClicked(false);
    setPublished(false);
  };

  const mergeArraysWithSortIds = (arr1: any, arr2: any) => {
    let currentSortId = 1;
    let arr2Index = 0;

    const combined = [];

    arr1.forEach((item: any) => {
      while (currentSortId < item.sortId && arr2Index < arr2.length) {
        combined.push({
          sortId: currentSortId++,
          ...arr2[arr2Index++],
        });
      }
      combined.push({ ...item });
      currentSortId++;
    });

    while (arr2Index < arr2.length) {
      combined.push({ sortId: currentSortId++, ...arr2[arr2Index++] });
    }
    console.log("combined =====", combined);

    return combined;
  };

  useEffect(() => {
    console.log("viewData=>", viewData);
  }, [viewData]);

  useEffect(() => {
    const getData = async () => {
      // Await the API call to fetch rails
      // const railResponse: any = await getRails();
      handleFeatTags("");
      setAllRailsData(meta?.railTypes);
      console.log("railResponse", meta);

      setIsLoading(true);
      try {
        const res: any = await getDraftViewById(viewId);
        const rails = res?.rails?.list?.length > 0 ? res?.rails?.list : [];
        setSortableData(rails);
        const resPlat: any = await getPlatforms();
        const resPlatDet: any = await getPlatformById(res.v_platform);
        setPlatformData(resPlatDet);
        setPlatformList(
          resPlat.list.map((pl: any) => ({ label: pl.name, value: pl.id }))
        );
        setMessage("You are editing a draft copy of " + res?.name + " Page");
        setViewData(res);
        setFile(res?.og_image);

        if (res?.v_parent && res.v_parent !== "") {
          const data: any = await getDraftViewById(res.v_parent);
          setParentViewData(data);
          const updatedData =
            data?.rails?.list?.length > 0
              ? data?.rails?.list?.map((rail: any) => ({
                  ...rail,
                  isParentRail: true,
                }))
              : [];
          const railsData =
            res?.rails?.list?.length > 0
              ? res?.rails?.list[0]?.sortId > 0
                ? res?.rails?.list
                : res?.rails?.list?.map((rail: any, i: number) => ({
                    ...rail,
                    sortId: i,
                  }))
              : [];
          const mergedRails = mergeArraysWithSortIds(railsData, updatedData);
          setSortableData([...mergedRails]);
        }

        setIsLoading(false);
      } catch (e) {
        try {
          const res: any = await createDraftView(viewId);
          const rails = res?.rails?.list?.length > 0 ? res?.rails?.list : [];
          setSortableData(rails);
          const resPlat: any = await getPlatforms();
          const resPlatDet: any = await getPlatformById(res.v_platform);
          setPlatformData(resPlatDet);
          setPlatformList(
            resPlat.list.map((pl: any) => ({ label: pl.name, value: pl.id }))
          );
          setMessage("You are editing a draft copy of " + res?.name + " Page");
          setViewData(res);
          setFile(res?.og_image);

          if (res?.v_parent && res.v_parent !== "") {
            const data: any = await getDraftViewById(res.v_parent);
            setParentViewData(data);
            const updatedData =
              data?.rails?.list?.length > 0
                ? data?.rails?.list?.map((rail: any) => ({
                    ...rail,
                    isParentRail: true,
                  }))
                : [];
            const railsData =
              res?.rails?.list?.length > 0
                ? res?.rails?.list[0]?.sortId > 0
                  ? res?.rails?.list
                  : res?.rails?.list?.map((rail: any, i: number) => ({
                      ...rail,
                      sortId: i,
                    }))
                : [];
            const mergedRails = mergeArraysWithSortIds(railsData, updatedData);
            setSortableData([...mergedRails]);
          }

          setIsLoading(false);
        } catch (e) {
          console.log("Error creating draft view", e);
          setError("Something went wrong. Please try again later.");
          setIsLoading(false);
        }
      }
    };
    getData();
  }, []);
  return (
    <>
      <BreadCrumb
        backRoute={`/views/${viewData?.v_platform}`}
        title={`Edit Page`}
        pageTitle="Mange Pages"
      />
      {success && <SnackBar message={success} onClose={() => setSuccess("")} />}
      {error && <SnackBar message={error} onClose={() => setError("")} />}
      {!isLoading && (
        <div className="card">
          <div className="card-body flex flex-col text-xs">
            <div className="flex justify-between items-center card px-4 py-2">
              <div className="flex flex-col gap-1">
                <h2 className="text-lg font-bold">
                  Manage : {viewData.name} (Platform - {platformData.name})
                </h2>
                <p className="text-mb-red">{message}</p>
              </div>
              <div className="flex gap-6">
                {selectedSection === 1 && (
                  <Popup
                    arrow
                    on={"click"}
                    position={"bottom right"}
                    closeOnDocumentClick
                    trigger={
                      <button className="text-mb-blue">
                        <Globe2 size={15} />
                      </button>
                    }
                  >
                    <div className="w-[200px] flex flex-col gap-0 border border-gray-300 bg-white mt-2 rounded-md overflow-hidden">
                      <div className="bg-mb-blue text-white py-1 px-2 font-bold">
                        Change Language
                      </div>
                      {meta.languages.map((l: string, i: number) => (
                        <button
                          key={i}
                          onClick={() => {
                            setI18n(l);
                          }}
                          className="px-4 py-2 bg-slate-100 border-b border-b-slate-300 hover:bg-mb-blue hover:text-white text-left flex items-center gap-1"
                        >
                          {i18n === l ? <Check size={10} /> : <></>}
                          {isoLangs[l].name}
                        </button>
                      ))}
                    </div>
                  </Popup>
                )}
                <button
                  onClick={() => setOpenAddPopup(true)}
                  className="bg-mb-blue rounded-md text-white hover:bg-mb-blue/30 hover:text-mb-blue px-4 py-2 flex items-center gap-1"
                >
                  Add Rail <Plus size={10} />{" "}
                </button>
              </div>
              <Popup
                modal
                open={openAddPopup}
                onClose={() => {
                  setOpenAddPopup(false);
                }}
                overlayStyle={{
                  background: "rgba(0,0,0,0.8)",
                  zIndex: 999999999,
                }}
                lockScroll
                closeOnDocumentClick
              >
                <div className="bg-white flex flex-col rounded-md md:w-[30rem]">
                  <div className="flex py-2 px-4 border-b border-b-mb-blue/50 justify-between items-center">
                    <h6>Add New</h6>
                    <button onClick={() => setOpenAddPopup(false)}>
                      <X size={15} />
                    </button>
                  </div>
                  <div className="max-h-[calc(theme('height.screen')_-_180px)] p-4 overflow-y-auto mb-5">
                    <div className="flex flex-col rounded-md border border-white-light dark:border-[#1b2e4b] h-[70vh] ">
                      {allRailsData &&
                        allRailsData.map((item: any, idx: any) => (
                          <div
                            key={idx + item?.name}
                            className="grid"
                            onClick={() => handleAdd(item)}
                          >
                            <div
                              key={item.idx}
                              className="border-b flex justify-between border-white-light dark:border-[#1b2e4b] px-4 py-2.5 bg-white text-black hover:bg-primary hover:text-mb-blue shadow-[0_1px_15px_1px_rgba(67,97,238,0.15)]"
                            >
                              <div>
                                <p>{item.name} </p>
                                <p className="text-xs">{item.description}</p>
                              </div>
                              <Plus />
                            </div>
                          </div>
                        ))}
                      <div className="flex justify-end gap-8 mt-4">
                        <button
                          onClick={() => setOpenAddPopup(false)}
                          className="bg-mb-blue rounded-md text-white hover:bg-mb-blue/30 hover:text-mb-blue px-4 py-2 flex items-center gap-1"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Popup>
            </div>

            <div className="tabs flex flex-nowrap items-center justify-start gap-0 max-w-full overflow-x-auto scrollbar-hide">
              <button
                onClick={() => {
                  setSelectedSection(0);
                  setI18n(meta.lang);
                }}
                className={`${
                  selectedSection === 0
                    ? "bg-white border-b-0 text-mb-blue"
                    : "bg-mb-blue/10 hover:bg-mb-blue/20"
                } rounded-tl-md border border-slate-300 px-4 py-2 flex items-center justify-center gap-2 text-nowrap min-w-[150px] text-center transition-all duration-500 z-10`}
              >
                {/* <Icon name={section.icon} size={15} className="" /> */}
                Rails
              </button>
              {viewData?.view_type === "listing" && (
                <button
                  onClick={() => {
                    setSelectedSection(1);
                  }}
                  className={`${
                    selectedSection === 1
                      ? "bg-white border-b-0 text-mb-blue"
                      : "bg-mb-blue/10 hover:bg-mb-blue/20"
                  } rounded-tr-md border border-slate-300 px-4 py-2 flex items-center justify-center gap-2 text-nowrap min-w-[150px] text-center transition-all duration-500 z-10`}
                >
                  {/* <Icon name={section.icon} size={15} className="" /> */}
                  SEO
                </button>
              )}
            </div>
            {selectedSection === 0 ? (
              <>
                {sortableData && sortableData.length > 0 ? (
                  <div className="border border-slate-200 rounded-md rounded-tl-none -mt-[2px]">
                    <Accordion>
                      <ReactSortable
                        list={sortableData}
                        setList={(v) => {
                          const updateSortId = v?.map(
                            (rail: any, index: number) => ({
                              ...rail,
                              sortId: index + 1,
                            })
                          );
                          setSaveDraftClicked(false);
                          setPublished(false);
                          setSortableData(updateSortId);
                          setViewData({
                            ...viewData,
                            rails: {
                              ...viewData.rails,
                              list: updateSortId?.filter(
                                (rail) => !rail?.isParentRail
                              ),
                            },
                          });
                        }}
                        className=""
                      >
                        {sortableData.map((rail: AnyObject, i: number) => {
                          return (
                            <Accordion.Panel
                              key={i + rail.id}
                              className={
                                rail?.isParentRail
                                  ? "bg-slate-100 text-xs"
                                  : "bg-white odd:bg-white even:bg-slate-50 text-xs"
                              }
                              header={
                                <div className="flex justify-between items-center">
                                  <div className="flex flex-col items-start justify-start gap-2">
                                    <div className="flex gap-2 items-center">
                                      <MoveVertical size={10} />
                                      <p>{rail.title}</p>{" "}
                                    </div>
                                    <p className="text-xs font-light block">
                                      Type: {rail.id}
                                    </p>
                                  </div>
                                  <Toggle
                                    disabled={rail.isParentRail}
                                    className="mr-3"
                                    checked={rail?.active}
                                    value={"Active"}
                                    checkedChildren={"Active"}
                                    unCheckedChildren={"Inactive"}
                                    onChange={(v) => {
                                      handleRailInfoChange(i, "active", v);
                                    }}
                                  />
                                </div>
                              }
                            >
                              <div
                                className={`w-full bg-white p-4 border border-gray-200 grid lg:grid-cols-2 gap-6 overflow-hidden`}
                              >
                                {!rail?.isParentRail && (
                                  <div className="col-span-full flex justify-end">
                                    <button
                                      onClick={() => {
                                        setViewData(() => {
                                          const filterData = {
                                            rails: {
                                              ...viewData.rails,
                                              list: viewData.rails.list.filter(
                                                (r: any, index: number) =>
                                                  index !== i
                                              ),
                                            },
                                          };
                                          return filterData;
                                        });
                                        setSortableData(
                                          sortableData?.filter(
                                            (rail: any, index: number) =>
                                              index !== i
                                          )
                                        );
                                      }}
                                      className="bg-mb-red  disabled:bg-gray-100 rounded-full right-1 top-1 text-white p-px"
                                    >
                                      <X size={15} />
                                    </button>
                                  </div>
                                )}
                                <div className="flex flex-col gap-px">
                                  <label className="text-xs">Rail Title</label>
                                  <div className="tabs flex flex-nowrap items-center justify-start gap-0 max-w-full overflow-x-auto scrollbar-hide">
                                    {meta.languages.map(
                                      (l: string, i: number) => (
                                        <button
                                          onClick={() => {
                                            setSelectedLang(l);
                                          }}
                                          className={`${
                                            selectedLang === l
                                              ? "bg-white text-mb-blue"
                                              : "bg-mb-blue/10 hover:bg-mb-blue/20"
                                          } 
                                        ${
                                          i === 0
                                            ? "rounded-tl-md"
                                            : i === meta.languages?.length - 1
                                            ? "rounded-tr-md"
                                            : ""
                                        }
                                         border border-slate-300 px-4 py-2 flex items-center justify-center gap-2 text-nowrap min-w-[50px] text-center transition-all duration-500 z-10`}
                                        >
                                          {/* <Icon name={section.icon} size={15} className="" /> */}
                                          {isoLangs[l].name}
                                        </button>
                                      )
                                    )}
                                  </div>
                                  <input
                                    disabled={rail.isParentRail}
                                    value={
                                      selectedLang === meta.lang
                                        ? rail.title
                                        : rail?.i18n
                                        ? rail?.i18n[selectedLang]?.title ?? ""
                                        : ""
                                    }
                                    onChange={(e: any) => {
                                      const i18nObj = {
                                        ...rail?.i18n,
                                        [selectedLang]: {
                                          title: e.target.value,
                                        },
                                      };
                                      if (selectedLang === meta.lang) {
                                        handleRailInfoChange(
                                          i,
                                          "title",
                                          e.target.value
                                        );
                                      } else {
                                        handleRailInfoChange(
                                          i,
                                          "i18n",
                                          i18nObj
                                        );
                                      }
                                    }}
                                    className="px-1 py-2 h-9 border border-mb-blue rounded-md w-full disabled:bg-gray-100 disabled:cursor-not-allowed"
                                    type="text"
                                  />
                                </div>
                                <div className="flex flex-col gap-px">
                                  <label className="text-xs">
                                    Rail Description
                                  </label>
                                  <input
                                    disabled={rail.isParentRail}
                                    value={rail.description}
                                    onChange={(e: any) =>
                                      handleRailInfoChange(
                                        i,
                                        "description",
                                        e.target.value
                                      )
                                    }
                                    className="px-1 py-2 h-9 border border-mb-blue rounded-md w-full disabled:bg-gray-100 disabled:cursor-not-allowed"
                                    type="text"
                                  />
                                </div>
                                <div className="flex flex-col gap-px">
                                  <label className="text-xs">
                                    Select Languages
                                  </label>
                                  <TagPicker
                                    disabled={rail.isParentRail}
                                    data={meta.languages.map((l: any) => ({
                                      label: isoLangs[l].name,
                                      value: l,
                                    }))}
                                    className="border !border-mb-blue rounded-md w-full"
                                    onChange={(v, e) =>
                                      handleRailInfoChange(i, "language", v)
                                    }
                                    value={rail.language || []}
                                  ></TagPicker>
                                </div>

                                <div className="flex flex-col gap-px">
                                  <label className="text-xs">Tags</label>
                                  <TagPicker
                                    disabled={rail.isParentRail}
                                    className="border !border-mb-blue rounded-md w-full"
                                    creatable
                                    data={tagsData?.map((l: any) => ({
                                      login: l,
                                      id: l,
                                    }))}
                                    cacheData={cacheTagData} //Option to cache value when searching asynchronously
                                    value={rail?.tags || []}
                                    style={{ width: 300 }}
                                    labelKey="login"
                                    valueKey="id"
                                    onChange={(data) => {
                                      handleRailInfoChange(i, "tags", data);
                                      console.log("tags data", data);
                                    }}
                                    onSearch={handleFeatTags}
                                    onSelect={handleSelectTag}
                                  />
                                </div>

                                {!viewData.v_parent && (
                                  <div className="flex flex-col gap-px">
                                    <label className="text-xs">
                                      Hide on Platforms
                                    </label>
                                    <TagPicker
                                      disabled={rail.isParentRail}
                                      data={platformList || []}
                                      className="border !border-mb-blue rounded-md w-full"
                                      onChange={(v, e) =>
                                        handleRailInfoChange(
                                          i,
                                          "excludePlatforms",
                                          v
                                        )
                                      }
                                      value={rail.excludePlatforms || []}
                                    ></TagPicker>
                                  </div>
                                )}
                                <div className="flex flex-col gap-px">
                                  <label className="text-xs">
                                    Included Countries
                                  </label>
                                  <TagPicker
                                    disabled={rail.isParentRail}
                                    data={Object.keys(countries).map(
                                      (c: string) => ({
                                        label: countries[c],
                                        value: c,
                                      })
                                    )}
                                    className="border !border-mb-blue rounded-md w-full"
                                    onChange={(v, e) =>
                                      handleRailInfoChange(
                                        i,
                                        "includedCountries",
                                        v
                                      )
                                    }
                                    value={rail.includedCountries || []}
                                    renderExtraFooter={() => (
                                      <div>
                                        <Checkbox
                                          checked={
                                            Object.keys(countries)?.length ===
                                            rail.includedCountries?.length
                                          }
                                          onChange={(value, checked) => {
                                            if (checked) {
                                              const allCountries =
                                                Object.keys(countries);
                                              handleRailInfoChange(
                                                i,
                                                "includedCountries",
                                                allCountries
                                              );
                                            } else {
                                              handleRailInfoChange(
                                                i,
                                                "includedCountries",
                                                []
                                              );
                                            }
                                          }}
                                        >
                                          Check all
                                        </Checkbox>
                                      </div>
                                    )}
                                  ></TagPicker>
                                </div>

                                <div className="flex flex-col gap-px">
                                  <label className="text-xs">
                                    Select Layouts
                                  </label>
                                  <SelectPicker
                                    disabled={rail.isParentRail}
                                    data={
                                      platformData.configuration &&
                                      platformData.configuration.layouts
                                        ? Object.keys(
                                            platformData.configuration.layouts
                                          ).map((k: string) => ({
                                            label: k,
                                            value: k,
                                          }))
                                        : []
                                    }
                                    className="border !border-mb-blue rounded-md w-full"
                                    onChange={(v, e) =>
                                      handleRailInfoChange(i, "layout", v)
                                    }
                                    value={rail.layout || []}
                                  ></SelectPicker>
                                </div>
                                <div className="flex flex-col gap-px">
                                  <label className="text-xs">Start Date</label>
                                  <DatePicker
                                    disabled={rail.isParentRail}
                                    oneTap
                                    format="MM/dd/yyyy HH:mm:ss"
                                    className="border !border-mb-blue rounded-md w-full"
                                    onChange={(v, e) =>
                                      handleRailInfoChange(i, "startDate", v)
                                    }
                                    value={
                                      rail.startDate
                                        ? new Date(rail.startDate)
                                        : null
                                    }
                                  />
                                </div>
                                <div className="flex flex-col gap-px">
                                  <label className="text-xs">End Date</label>
                                  <DatePicker
                                    disabled={rail.isParentRail}
                                    oneTap
                                    format="MM/dd/yyyy HH:mm:ss"
                                    className="border !border-mb-blue rounded-md w-full"
                                    onChange={(v, e) =>
                                      handleRailInfoChange(i, "endDate", v)
                                    }
                                    value={
                                      rail.endDate
                                        ? new Date(rail.endDate)
                                        : null
                                    }
                                  />
                                </div>

                                {/* Editorial extend code start */}

                                {(rail?.type === "editorial" ||
                                  rail?.type === "filter") && (
                                  <EditorialEditRailsSection
                                    disabled={rail.isParentRail}
                                    rail={rail}
                                    i={i}
                                    handleRailInfoChange={handleRailInfoChange}
                                    railType={rail?.type}
                                  />
                                )}

                                {/* Editorial extend code end */}
                              </div>
                            </Accordion.Panel>
                          );
                        })}
                      </ReactSortable>
                    </Accordion>
                  </div>
                ) : (
                  <p className="text-xs bg-gray-200 p-4 text-center">
                    No rails added yet
                  </p>
                )}
                <div className="mt-4 overflow-x-auto scrollbar-hide">
                  <div className="flex gap-4 items-center justify-start lg:justify-end font-bold">
                    <button
                      disabled={saveDraftClicked || published}
                      onClick={handleSaveDraftRails}
                      className="bg-mb-blue rounded-md text-white hover:bg-mb-blue/30 hover:text-mb-blue px-4 py-2 flex items-center gap-1 text-nowrap disabled:bg-gray-200 disabled:hover:bg-gray-200 disabled:hover:text-white"
                    >
                      <SaveAll size={15} />
                      Save Draft
                    </button>
                    <button
                      disabled={!saveDraftClicked || published}
                      onClick={handlePublishRails}
                      className="bg-mb-green rounded-md text-white hover:bg-mb-green/30 hover:text-mb-green px-4 py-2 flex items-center gap-1 text-nowrap disabled:bg-gray-200 disabled:hover:bg-gray-200 disabled:hover:text-white"
                    >
                      <ShieldCheck size={15} />
                      Publish
                    </button>
                  </div>
                </div>
              </>
            ) : (
              <div className="border border-slate-200 rounded-md -mt-[2px] rounded-tl-none p-6">
                <div className="grid md:grid-cols-2 gap-6 mt-4">
                  <div className="mb-3">
                    <label
                      htmlFor="name"
                      className="inline-block mb-2 text-base font-medium"
                    >
                      Meta Title
                    </label>
                    <input
                      type="meta_title"
                      id="name"
                      className="px-1 py-2 h-9 border !border-mb-blue rounded-md w-full disabled:bg-gray-100 disabled:cursor-not-allowed"
                      placeholder="Enter name"
                      name="name"
                      onChange={(e) => {
                        if (i18n === meta.lang) {
                          handleChangeName(e.target.value, "meta_title");
                        } else {
                          handlerI18nValueChange(e.target.value, "meta_title");
                        }
                      }}
                      value={
                        i18n === meta.lang
                          ? viewData?.meta_title
                          : viewData?.i18n
                          ? viewData?.i18n[i18n]?.meta_title || ""
                          : ""
                      }
                    />
                  </div>{" "}
                  <div className="mb-3">
                    <label
                      htmlFor="meta_description"
                      className="inline-block mb-2 text-base font-medium"
                    >
                      Meta Description
                    </label>
                    <input
                      type="text"
                      id="name"
                      className="px-1 py-2 h-9 border !border-mb-blue rounded-md w-full disabled:bg-gray-100 disabled:cursor-not-allowed"
                      placeholder="Enter name"
                      name="name"
                      onChange={(e) => {
                        if (i18n === meta.lang) {
                          handleChangeName(e.target.value, "meta_description");
                        } else {
                          handlerI18nValueChange(
                            e.target.value,
                            "meta_description"
                          );
                        }
                      }}
                      // value={viewData?.meta_description}
                      value={
                        i18n === meta.lang
                          ? viewData?.meta_description
                          : viewData?.i18n
                          ? viewData?.i18n[i18n]?.meta_description || ""
                          : ""
                      }
                    />
                  </div>{" "}
                  <div className="mb-3">
                    <label
                      htmlFor="meta_keywords"
                      className="inline-block mb-2 text-base font-medium"
                    >
                      Meta Keywords
                    </label>
                    <input
                      type="text"
                      id="name"
                      className="px-1 py-2 h-9 border !border-mb-blue rounded-md w-full disabled:bg-gray-100 disabled:cursor-not-allowed"
                      placeholder="Enter name"
                      name="meta_keywords"
                      onChange={(e) => {
                        if (i18n === meta.lang) {
                          handleChangeName(e.target.value, "meta_keywords");
                        } else {
                          handlerI18nValueChange(
                            e.target.value,
                            "meta_keywords"
                          );
                        }
                      }}
                      // value={viewData?.meta_keywords}
                      value={
                        i18n === meta.lang
                          ? viewData?.meta_keywords
                          : viewData?.i18n
                          ? viewData?.i18n[i18n]?.meta_keywords || ""
                          : ""
                      }
                    />
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="og_image"
                      className="inline-block mb-2 text-base font-medium"
                    >
                      Og Image :
                    </label>
                    <input
                      className="block mb-2"
                      type="file"
                      onChange={onUpload}
                    />
                    {file !== "" && file && (
                      <img
                        src={`${process.env.REACT_APP_IMAGE_CDN}${file}`}
                        width={100}
                        height={100}
                        alt="uploadedfile"
                      />
                    )}
                  </div>
                </div>
                <div className="flex justify-end">
                  <button
                    className="bg-mb-blue text-white rounded-md hover:bg-mb-blue/30 hover:text-mb-blue px-4 py-2 flex items-center gap-1"
                    onClick={handleSaveDraftRails}
                  >
                    Save Changes
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {isLoading && <MbLoader />}
    </>
  );
};

export default ListRails;
